import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import FileUploadHandler from '../mycomponents/FileUploadHandler';
import { Input } from '../components/Input';
import { CardSkills } from '../mycomponents/CardSkills';
import { mdiFileCogOutline, mdiPlus, mdiAccountOutline, mdiBriefcaseCheckOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { arraysEqual } from '../utils/dataTools';
import { convertTimestampToDate } from '../utils/dateUtils';
import departements from '../utils/departements';
import PartnerNameById from '../mycomponents/PartnerNameById';
import { Button } from '../components/Button';
import { Select } from '../mycomponents/Select';
import { CardApplicantWorkExperience } from '../mycomponents/CardApplicantWorkExperience/CardApplicantWorkExperience';
import { applicantSkillLevelOptions } from '../utils/StatusSkills';

const transformDepartements = (departements) => {
    return departements.map(departement => ({
        value: departement.num_dep.toString(),
        label: departement.dep_name,
        region_name: departement.region_name
    }));
};

const EditApplicant = ({ id, onUpdateApplicant, isEditMode }) => {
    const { t } = useTranslation();
    const auth = useAuth();
    const tokenParsed = useAuth();
    const [defDep, setDefDep] = useState();
    const [editingMode, setEditingMode] = useState(true);
    const [isSubmitting, setIsSubmitting]= useState(false);
    
    let partner_id;
    if (tokenParsed.partner_id) {
        partner_id = tokenParsed.partner_id;
    } else {
        partner_id = null;
    }
    const [formData, setFormData] = useState({
        partner_id: partner_id,
        status: '',
        resume: '',
        label: '',
        rate: 0,
        last_name: '',
        first_name: '',
        trigraph: '',
        birth_date: '',
        birth_place: '',
        residence: '',
        languages: [],
        skills: [],
        diplomas: []
    });

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    useEffect(() => {
        if (id) {
            const fetchData = async () => {
                try {
                    const responseApplicant = await api.get(`/applicant/${id}`);
                    setFormData(responseApplicant.data);
                    const match = departements.find(departement => departement.num_dep.toString() === responseApplicant.data.residence);
                    setDefDep(match);
                } catch (error) {
                    console.error(t('errors.fetchData'), error);
                }
            };

            fetchData();
        }
    }, [id, isEditMode, t]);

    useEffect(() => {
        if (isEditMode) {
            onUpdateApplicant(formData);
        }
    }, [formData, onUpdateApplicant, isEditMode]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            residence: value
        }));
    };

    const handleUploadSuccess = (data) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            resume: data
        }));
        console.log(t('messages.uploadSuccess'), data);
    };

    const handleSubmit = async (event) => {
        if (event) event.preventDefault();
    
        if (isSubmitting) {
            // Prevent multiple submissions
            return;
        }
    
        setIsSubmitting(true); // Lock the submit function
    
        try {
            if (id) {
                // Update existing applicant
                await api.put(`/applicant/${id}`, formData);
                console.log(t('messages.updateSuccess'));
            } else {
                await api.post('/applicant', formData);
                console.log(t('messages.createSuccess'));
            }
        } catch (error) {
            console.error(t('errors.submit'), error);
        }
    };
    

    const [skillsData, setSkillsData] = useState(formData.skills);
    const handleSaveSkills = (updatedSkills) => {
        if (!arraysEqual(skillsData, updatedSkills)) {
            setSkillsData(updatedSkills);
            setFormData(prevFormData => ({
                ...prevFormData,
                skills: updatedSkills
            }));
            console.log(t('messages.skillsUpdated'));
        }
    };

    const [diplomasData, setDiplomasData] = useState(formData.diplomas);
    const handleSaveDiplomas = (updatedDiplomas) => {
        if (!arraysEqual(diplomasData, updatedDiplomas)) {
            setDiplomasData(updatedDiplomas);
            setFormData(prevFormData => ({
                ...prevFormData,
                diplomas: updatedDiplomas
            }));
            console.log(t('messages.diplomasUpdated'));
        }
    };

    const [languagesData, setLanguagesData] = useState(formData.languages);
    const handleSaveLanguages = (updatedLanguages) => {
        if (!arraysEqual(languagesData, updatedLanguages)) {
            setLanguagesData(updatedLanguages);
            setFormData(prevFormData => ({
                ...prevFormData,
                languages: updatedLanguages
            }));
            console.log(t('messages.languagesUpdated'));
        }
    };

    const [experiencesData, setExperiencesData] = useState(formData.workexperiences);
    const handleSaveExperiences = (updatedExperiences) => {
        if (!arraysEqual(experiencesData, updatedExperiences)) {
            setExperiencesData(updatedExperiences);
            setFormData(prevFormData => ({
                ...prevFormData,
                workexperiences: updatedExperiences
            }));
            console.log(t('messages.experiencesUpdated'));
        }
    };

    const setCollapsed = async () => {
        if (editingMode) {
            await handleSubmit();
        }
        setEditingMode(!editingMode);
    };

    return (
        <div className='full-width'>

            <div className="columns-container">
                <div className="columnL">
                    <div>
                        <div className='card-component applicant-main width-fix'>
                            <div className="card-header-alt">
                                <div>
                                    <Icon
                                        path={mdiAccountOutline}
                                        color="#CED4DD"
                                        size="20px" />
                                    <span className="card-title">{t('applicant.information')}</span>
                                </div>
                                <div className="headr">
                                    <Button
                                        appearance="secondary"
                                        className="button-instance"
                                        hasAfterIcon={false}
                                        hasBeforeIcon={false}
                                        hasDropdownIndicator={false}
                                        intent="none"
                                        label={editingMode ? t('actions.save') : t('actions.edit')}
                                        size="small"
                                        stateProp="normal"
                                        type="default"
                                        customBeforeIcon={mdiAccountOutline}
                                        customAfterIcon={mdiPlus}
                                        onClick={setCollapsed}
                                    />
                                </div>
                            </div>
                            <form onSubmit={handleSubmit}>
                                <div className="v-padding-fix">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.resume')}:
                                            <FileUploadHandler onUploadSuccess={handleUploadSuccess} resumeData={formData.resume} editingMode={editingMode} />
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.title')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="label"
                                                    value={formData.label}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.label}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.partner')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            <PartnerNameById id={formData.partner_id} type="partner" />
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.rate')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="number"
                                                    name="rate"
                                                    value={formData.rate}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode} />
                                            ) : `${formData.rate} ${t('currency.euroPerDay')}`}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.lastName')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.last_name}
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.firstName')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.first_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.birthDate')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="date"
                                                    name="birth_date"
                                                    value={formData.birth_date}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : convertTimestampToDate(formData.birth_date)}
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.birthPlace')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="birth_place"
                                                    value={formData.birth_place}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.birth_place}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('applicant.residence')} :
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Select
                                                    name="residence"
                                                    options={transformDepartements(departements)}
                                                    value={formData.residence}
                                                    onChange={handleSelectChange}
                                                />
                                            ) : (
                                                `${defDep?.dep_name} (${formData.residence})`
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
                <div className="columnR">
                    <div>
                        <CardSkills
                            skills={formData.skills}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveSkills}
                            title={t('applicant.skills')}
                            placeHolder={t('applicant.newSkill')}
                            editButton={false}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                            lCollapsed={false}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={formData.diplomas}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveDiplomas}
                            title={t('applicant.diplomas')}
                            placeHolder={t('applicant.newDiploma')}
                            editButton={false}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                            hasLevelSelect={false}
                            lCollapsed={false}
                        />
                    </div>
                    <div>
                        <CardApplicantWorkExperience
                            experiences={formData.workexperiences}
                            iconPath={mdiBriefcaseCheckOutline}
                            saveExperiences={handleSaveExperiences}
                            title={t('applicant.experiences')}
                            placeHolder={t('applicant.newExperience')}
                            editButton={false}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                            lCollapsed={false}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={formData.languages}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveLanguages}
                            title={t('applicant.languages')}
                            placeHolder={t('applicant.newLanguage')}
                            editButton={false}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                            lCollapsed={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditApplicant;
