export const getSkillColor = (level) => ({
  "0": "warning",
  "1": "informative",
  "2": "positive",
  "3": "negative"
})[level] || 'neutral';

export const getSkillLabel = (level) => ({
  "0":SkillLevelOptions[0].label,
  "1": SkillLevelOptions[1].label,
  "2": SkillLevelOptions[2].label,
  "3": SkillLevelOptions[3].label
})[level] || 'Compétence';

export const SkillLevelOptions = [
  { label: 'Souhaité',      value: '0' },
  { label: 'Principale',    value: '1' },
  { label: 'Maitrîse',      value: '2' },
  { label: 'Rédhibitoire',  value: '3' },
];

export const applicantSkillLevelOptions = [
  { label: 'Débutant',      value: '0' },
  { label: 'Intermédiaire', value: '1' },
  { label: 'Expérimenté',   value: '2' },
  { label: 'Maitrîse',      value: '3' },
  { label: 'Non Maitrîsé',  value: '99' },
];

export const applicantStatusOptions = [
  { color: "warning",     label: "Inactif" },
  { color: "informative", label: "Positionné" },
  { color: "positive",    label: "En mission" },
  { color: "negative",    label: "Intercontrat" }
];

export const getApplicantStatusColor = (index) => {
  try {
      return applicantStatusOptions[index].color;
  } catch (e) {
      return 'neutral';
  }
};

export const getApplicantStatusLabel = (index) => {
  try {
      return applicantStatusOptions[index].label;
  } catch (e) {
      return '';
  }
};