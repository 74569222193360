// Home.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MissionsList from '../mycomponents/MissionsList';
import { Button } from '../components/Button';
import { mdiPlus } from '@mdi/js';
import Drawer from '@mui/material/Drawer';
import EditMissionPage from './EditMission';

const ListMissionsPage = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const openDrawer = (applicationId) => {
    setDrawerOpen(true);
  };
  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <div className='list-rfp-container'>
      <div className='list-rfp-header'>
        <div className='list-rfp-header-content-l'>
          {t('listMissions.header')}
        </div>
        <div className='list-rfp-header-content-r'>
          
        </div>
      </div>
      <div>
        <MissionsList />
      </div>
      <div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={closeDrawer}
        >
          <EditMissionPage />
        </Drawer>
      </div>

    </div>
  );
}

export default ListMissionsPage;
