// utils/errorTranslations.js

export const fieldLabels = {
    name: 'Nom',
    siren: 'SIREN',
    vat_nbr: 'Numéro TVA',
    rcs: 'RCS',
    email: 'Email',
    firstName: 'Prénom',
    lastName: 'Nom',
    phone: 'Téléphone',
    // Ajoutez d'autres champs si nécessaire
  };
  
  export const errorMessages = {
    unique: 'doit être unique.',
    not_unique: 'doit être unique.',
    notNull: 'ne peut pas être vide.',
    // Ajoutez d'autres types d'erreurs si nécessaire
  };
  