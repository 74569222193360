import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Status } from "../components/Status";
import { mdiMagnify, mdiMenuSwap, mdiPlus } from '@mdi/js';
import Icon from '@mdi/react';
import { Input } from '../components/Input';
import { Button } from '../components/Button';
import { convertTimestampToDate } from '../utils/dateUtils';
import { getApplicantStatusColor, getApplicantStatusLabel } from '../utils/StatusSkills';
import { useNavigate } from "react-router-dom";

const ApplicantListAndApply = ({ mode = 'list', rfpId, onSelectApplicant }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const [applicants, setApplicants] = useState([]);
  const [partners, setPartners] = useState({});
  const [sortField, setSortField] = useState('createdAt');
  const [sortDirection, setSortDirection] = useState('desc');
  const [filterText, setFilterText] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();
    }
  }, [auth.initialized, auth.updateToken]);

  useEffect(() => {
    const fetchApplicants = async () => {
      try {
        const response = await api.get(`/applicant`);
        setApplicants(response.data.items);

        const partnerIds = response.data.items.map((row) => row.partner_id);
        const partnerResponses = await Promise.all(partnerIds.map((partnerId) => api.get(`/partner/${partnerId}`)));
        const fetchedPartners = partnerResponses.reduce((acc, partnerResponses) => {
          acc[partnerResponses.data.id] = partnerResponses.data.name;
          return acc;
        }, {});
        setPartners(fetchedPartners);
      } catch (error) {
        console.error('Error fetching applicants:', error);
      }
    };
    fetchApplicants();
  }, []);

  const filteredAndSortedApplicants = applicants
    .filter(row => {
      const rowString = [
        row.first_name,
        row.last_name,
        row.label,
        row.seniority,
        partners[row.partner_id],
      ].join(' ').toLowerCase();

      return rowString.includes(filterText.toLowerCase());
    })
    .sort((a, b) => {
      if (sortField && a[sortField] && b[sortField]) {
        if (sortDirection === 'asc') {
          return a[sortField].toString().localeCompare(b[sortField].toString(), undefined, { numeric: true });
        } else {
          return b[sortField].toString().localeCompare(a[sortField].toString(), undefined, { numeric: true });
        }
      }
      return 0;
    });

  const createApplicantForm = () => {
    navigate('/createApplicant');
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left" colSpan={3}>
              <Input
                type="text"
                placeholder={t('applicantListAndApply.searchPlaceholder')}
                value={filterText}
                onChange={e => setFilterText(e.target.value)}
                customWidth="160px"
                iconBefore={mdiMagnify}
              />
            </TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="left"></TableCell>
            <TableCell align="right" colSpan={2}>
              <Button
                appearance="tertiary"
                className="button-instance"
                hasAfterIcon={false}
                hasBeforeIcon={true}
                hasDropdownIndicator={false}
                intent="positive"
                label={t('applicantListAndApply.addApplicant')}
                size="small"
                stateProp="normal"
                type="default"
                customBeforeIcon={mdiPlus}
                customAfterIcon={mdiPlus}
                onClick={createApplicantForm}
              />
            </TableCell>
          </TableRow>
          {filteredAndSortedApplicants.length > 0 ? (
            <TableRow>
              <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '0px', border: 0, width: 10 }} width="10px">
                <span><Icon path={mdiMenuSwap} size={1} onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')} /></span>
              </TableCell>
              <TableCell align="left"><span className="list-table-header">{t('applicantListAndApply.sort')}</span></TableCell>
              <TableCell align="left" onClick={() => setSortField('name')}><span className="list-table-header">{t('applicantListAndApply.name')}</span></TableCell>
              <TableCell align="left"><span className="list-table-header">{t('applicantListAndApply.title')}</span></TableCell>
              <TableCell align="left"><span className="list-table-header">{t('applicantListAndApply.status')}</span></TableCell>
              <TableCell align="left"><span className="list-table-header">{t('applicantListAndApply.partner')}</span></TableCell>
              <TableCell align="left"><span className="list-table-header">{t('applicantListAndApply.rate')}</span></TableCell>
              <TableCell align="left"><span className="list-table-header">{t('applicantListAndApply.seniority')}</span></TableCell>
              <TableCell align="left"><span className="list-table-header">{t('applicantListAndApply.creationDate')}</span></TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell align="center" colSpan={9}>
                <Button
                  appearance="tertiary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={true}
                  hasDropdownIndicator={false}
                  intent="positive"
                  label={t('applicantListAndApply.addApplicant')}
                  size="small"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiPlus}
                  customAfterIcon={mdiPlus}
                  onClick={createApplicantForm}
                />
              </TableCell>
            </TableRow>
          )}
        </TableHead>
        <TableBody>
          {filteredAndSortedApplicants.map((row) => (
            <TableRow
              key={row.id}
              hover={true}
              onClick={() => onSelectApplicant(row.id)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              style={{ cursor: 'pointer' }}
            >
              <TableCell></TableCell>
              <TableCell align="left"><span className="applicants-list-acronym"><div className="item-applicants-list-acronym">{row.trigraph}</div></span></TableCell>
              <TableCell align="left"><span className="list-rfp-title">{row.first_name} {row.last_name}</span></TableCell>
              <TableCell align="left"><span className="list-rfp-content">{row.label}</span></TableCell>
              <TableCell align="left"><Status className="status-instance" color={getApplicantStatusColor(row.status)} emphasis="strong" label={getApplicantStatusLabel(row.status)} style={{ width: '100px' }} /></TableCell>
              <TableCell align="left"><span className="list-rfp-content">{partners[row.partner_id]}</span></TableCell>
              <TableCell align="left"><span className="list-rfp-content">{row.rate}€</span></TableCell>
              <TableCell align="left"><span className="list-rfp-content">{row.seniority}</span></TableCell>
              <TableCell align="left"><span className="list-rfp-content">{convertTimestampToDate(row.createdAt)}</span></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ApplicantListAndApply;
