import * as React from 'react';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Input } from '../components/Input';
import { Status } from "../components/Status";
import { convertTimestampToDate } from '../utils/dateUtils';
import FilterModal from './FilterModal'; // Assurez-vous que le chemin d'importation est correct
import { Button } from '../components/Button';
import { Tag } from '../components/Tag';
import { mdiFilterVariant, mdiMagnify, mdiMenuSwap } from '@mdi/js';
import Icon from '@mdi/react';
import TablePaginationActions from './TablePaginationActions';

export default function BasicTable() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [rfps, setRfps] = useState([]);
    const [clients, setClients] = useState({});
    const [applicationCounts, setApplicationCounts] = useState({});
    const [sortField, setSortField] = useState('createdAt');
    const [sortDirection, setSortDirection] = useState('desc');
    const [filterText, setFilterText] = useState('');
    const [filters, setFilters] = useState({
        client: '',
        status: '',
        typology: '',
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const auth = useAuth();
    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    const { userRoles } = useAuth();
    const isAdmin = userRoles.includes('admin');

    const statusOptions = useMemo(() => [
        { value: 0, color: "warning", label: t('rfpStatusDraft') },
        { value: 1, color: "informative", label: t('rfpStatusReview') },
        { value: 2, color: "positive", label: t('rfpStatusOpen') },
        { value: 3, color: "negative", label: t('rfpStatusCanceled') },
        { value: 4, color: "brand", label: t('rfpStatusIncoming') },
        { value: 99, color: "negative", label: t('rfpStatusClosed') }
    ], []);

    const filteredAndSortedRfps = useMemo(() => {
        return rfps
            .filter(row => {
                const rowString = [
                    row.eq_ref,
                    row.client_ref,
                    row.rfp_title,
                    clients[row.id_client],
                    row.client_ref,
                ].join(' ').toLowerCase();

                const matchesTextFilter = rowString.includes(filterText.toLowerCase());
                const matchesClientFilter = !filters.client || clients[row.id_client] === filters.client;
                const matchesStatusFilter = filters.status === '' || row.status === parseInt(filters.status);
                const matchesTypologyFilter = !filters.typology || row.offer_type === filters.typology;

                return matchesTextFilter && matchesClientFilter && matchesStatusFilter && matchesTypologyFilter;
            })
            .sort((a, b) => {
                if (sortField && a[sortField] && b[sortField]) {
                    if (sortDirection === 'asc') {
                        return a[sortField].toString().localeCompare(b[sortField].toString(), undefined, { numeric: true });
                    } else {
                        return b[sortField].toString().localeCompare(a[sortField].toString(), undefined, { numeric: true });
                    }
                }
                return 0;
            });
    }, [rfps, clients, filterText, filters, sortField, sortDirection]);

    const paginatedRfps = useMemo(() => {
        return filteredAndSortedRfps.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    }, [filteredAndSortedRfps, page, rowsPerPage]);

    const getStatusColor = useCallback((index) => {
        try {
            return statusOptions.find(option => option.value === index).color;
        } catch (e) {
            return 'neutral';
        }
    }, [statusOptions]);

    const getStatusLabel = useCallback((index) => {
        try {
            return statusOptions.find(option => option.value === index).label;
        } catch (e) {
            return 'AO';
        }
    }, [statusOptions]);

    useEffect(() => {
        const fetchRfps = async () => {
            try {
                const response = await api.get(`/rfp`);
                setRfps(response.data.items);

                const clientIds = response.data.items.map((row) => row.id_client);
                const clientResponses = await Promise.all(clientIds.map((clientId) => api.get(`/client/${clientId}`)));
                const fetchedClients = clientResponses.reduce((acc, clientResponse) => {
                    acc[clientResponse.data.id] = clientResponse.data.name;
                    return acc;
                }, {});
                setClients(fetchedClients);
                const rfpIds = response.data.items.map((row) => row.id);

                const applicationsCountPromises = rfpIds.map((rfpId) =>
                    api.get(`/rfp/countapplications/${rfpId}`).then(response => ({
                        rfpId: rfpId,
                        count: response.data.count
                    }))
                );

                const applicationsCountResponses = await Promise.all(applicationsCountPromises);

                const fetchedApplicationsCounts = applicationsCountResponses.reduce((acc, { rfpId, count }) => {
                    acc[rfpId] = count;
                    return acc;
                }, {});

                setApplicationCounts(fetchedApplicationsCounts);

            } catch (error) {
                console.error('Error fetching RFPs:', error);
            }
        };
        fetchRfps();
    }, []);

    const handleFilterChange = useCallback((name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);

    const resetFilters = useCallback(() => {
        setFilters({
            client: '',
            status: '',
            typology: '',
        });
    }, []);

    const closeTag = (key) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: ''
        }));
    }

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filterOptions = [
        { key: 'client', label: t('rfpFilterOptionLabelClient'), defaultLabel: t('rfpFilterOptionDefaultLabelClient'), options: Object.values(clients).map(client => ({ value: client, label: client })) },
        { key: 'status', label: t('rfpFilterOptionLabelStatus'), defaultLabel: t('rfpFilterOptionDefaultLabelStatus'), options: statusOptions.map(option => ({ value: option.value.toString(), label: option.label })) },
        { key: 'typology', label: t('rfpFilterOptionLabelTypology'), defaultLabel: t('rfpFilterOptionDefaultLabelTypology'), options: Array.from(new Set(rfps.map(rfp => rfp.offer_type))).map(typology => ({ value: typology, label: typology })) },
    ];

    const headerStyles = {};
    let colCount;
    if (isAdmin) {
        colCount = 9;
    }
    else {
        colCount = 7;
    }

    return (
        <div>
            <FilterModal
                open={modalOpen}
                handleClose={closeModal}
                filters={filters}
                filterOptions={filterOptions}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
            />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={colCount}>
                                <div className='flex-fix'>
                                <div className='search244' >
                                <Input
                                    type="text"
                                    placeholder={t('searchPlaceholder')}
                                    value={filterText}
                                    onChange={e => setFilterText(e.target.value)}
                                    iconBefore={mdiMagnify}
                                />
                                </div>
                                <div className='h-padding-s'>
                                {filterOptions.map((filterOption) => {
                                    const option = statusOptions.find(option => option.value.toString() === filters[filterOption.key]);
                                    return filters[filterOption.key] ? (
                                        <Tag
                                            key={filterOption.key}
                                            className="tag-instance"
                                            hasCloseButton={true}
                                            label={option ? option.label : filters[filterOption.key]}
                                            prefix={filterOption.label}
                                            states="default"
                                            onClickClose={e => closeTag(filterOption.key)}
                                        />
                                    ) : null;
                                })}
                                </div>
                                </div>
                            </TableCell>
                            <TableCell align="left" >
                                
                            </TableCell>
                            <TableCell align='right'>
                                <Button
                                    appearance="secondary"
                                    className="button-instance"
                                    hasAfterIcon={false}
                                    hasBeforeIcon={true}
                                    hasDropdownIndicator={false}
                                    intent="none"
                                    label={t('filterToggleButtonLabel')}
                                    size="small"
                                    stateProp="normal"
                                    type="default"
                                    customBeforeIcon={mdiFilterVariant}
                                    customAfterIcon={mdiFilterVariant}
                                    onClick={openModal}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow sx={{ ...headerStyles }}>
                            <TableCell sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '0px',  border:0, width:10 }}><span ><Icon path={mdiMenuSwap} size={1} onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')} /></span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>{t('rfpTableHeaderRef')}</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>{t('rfpTableHeaderTitle')}</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>{t('rfpTableHeaderClient')}</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>{t('rfpTableHeaderClientRef')}</span></TableCell>
                            {isAdmin && (
                                <TableCell align="left"><span className='list-table-header'>{t('rfpTableHeaderStatus')}</span></TableCell>
                            )}
                            <TableCell align="left" onClick={() => setSortField('rfp_last_date')}><span className='list-table-header'>{t('rfpTableHeaderEndDate')}</span></TableCell>
                            <TableCell align="left" onClick={() => setSortField('createdAt')}><span className='list-table-header'>{t('rfpTableHeaderCreationDate')}</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>{t('rfpTableHeaderType')}</span></TableCell>
                            {isAdmin && (
                                <TableCell align="center"><span className='list-table-header'>{t('rfpTableHeaderApplications')}</span></TableCell>
                            )}

                            <TableCell align="left"><span className='list-table-header'>{t('rfpTableHeaderAmount')}</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedRfps.map((row) => (
                            <TableRow
                                key={row.id}
                                hover={true}
                                onClick={() => navigate(`/rfp/${row.id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }} // Fixed height added here
                                style={{
                                    cursor: 'pointer',
                                    
                                }}
                            >
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}></TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0 }}><span className='list-rfp-eq_ref'>{row.eq_ref}</span></TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '16px', paddingRight: '8px', border:0  }}><span className='list-rfp-title'>{row.rfp_title}</span></TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px',  border:0  }}><div className='cell-text'><div className='cell-text-label'>{clients[row.id_client]}</div>{row.client_mkt && (<div className='cell-text-text-wrapper'>{row.client_mkt}</div>)}</div></TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px',  border:0, width:100  }}><span className='list-rfp-eq_ref list-overflow'>{row.client_ref}</span></TableCell>
                                {isAdmin && (
                                    <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', border:0  }}>
                                        <Status className="status-instance" color={getStatusColor(row.status)} emphasis="strong" label={getStatusLabel(row.status)} style={{ width: '100px' }} />
                                    </TableCell>
                                )}
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0  }}><span className='list-rfp-content'>{convertTimestampToDate(row.rfp_last_date)}</span></TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0  }}><span className='list-rfp-content'>{convertTimestampToDate(row.createdAt)}</span></TableCell>
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border:0  }}><span className='list-rfp-content'>{row.offer_type}</span></TableCell>
                                {isAdmin && (
                                    <TableCell align="center" sx={{ paddingTop: '0px', paddingBottom: '0px', border:0  }} ><span className={`list-rfp-content ${applicationCounts[row.id] > 0 ? 'counter number' : ''}`}>{applicationCounts[row.id]}</span></TableCell>
                                )}
                                <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', border:0  }}><span className='list-rfp-content'>{row.amount}€</span></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    className="custom-pagination"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredAndSortedRfps.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('paginationLinesPerPage')}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count, page }) => {
                        const totalPages = Math.ceil(count / rowsPerPage);
                        return `${page + 1} / ${totalPages}`;
                    }}
                />
            </TableContainer>
        </div>
    );
}
