import * as React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { useNavigate } from 'react-router-dom';
import { Input } from '../components/Input';
import { Status } from "../components/Status";
import TablePaginationActions from './TablePaginationActions';
import FilterModal from './FilterModal';
import { Button } from '../components/Button';
import { mdiFilterVariant, mdiMagnify, mdiMenuSwap } from '@mdi/js';
import { Tag } from '../components/Tag';
import Icon from '@mdi/react';

export default function BasicTable() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [partners, setPartners] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const auth = useAuth();

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    const [modalOpen, setModalOpen] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [sortField, setSortField] = useState('createdAt'); // Default sort field
    const [sortDirection, setSortDirection] = useState('desc'); // 'asc' or 'desc'
    const [filterText, setFilterText] = useState(''); // For text search
    const [filters, setFilters] = useState({
        active: '1',
    });
    const filteredAndSortedPartners = partners
        .filter(row => {
            const rowString = [
                row.name,
                row.contact?.name,
            ].join(' ').toLowerCase();

            const textMatch = rowString.includes(filterText.toLowerCase());
            const activeMatch = filters.active === '' || (row.active != null && row.active.toString() === filters.active);

            return textMatch && activeMatch;
        })
        .sort((a, b) => {
            if (sortField && a[sortField] != null && b[sortField] != null) {
                if (sortDirection === 'asc') {
                    return a[sortField].toString().localeCompare(b[sortField].toString(), undefined, { numeric: true });
                } else {
                    return b[sortField].toString().localeCompare(a[sortField].toString(), undefined, { numeric: true });
                }
            }
            return 0;
        });

    useEffect(() => {
        const fetchPartners = async () => {
            try {
                const params = {
                    _page: page + 1, // Convert 0-based to 1-based
                    _limit: rowsPerPage,
                    _sort: sortField,
                    _order: sortDirection,
                };

                if (filterText.trim() !== '') {
                    params.filterText = filterText.trim();
                }

                if (filters.active !== '') {
                    params.active = filters.active; // '1' or '0'
                }

                console.log('Fetching partners with params:', params); // Debugging

                const response = await api.get(`/partner`, { params });

                console.log('API Response:', response.data); // Debugging

                const partnersData = response.data.items;

                const totalCount = response.data.total;
                setTotalCount(totalCount);

                // Fetch statistics for each partner
                const partnerStatisticsPromises = partnersData.map(partner =>
                    api.get(`/stats/partner-statistics/${partner.id}`)
                );

                const partnerStatisticsResponses = await Promise.all(partnerStatisticsPromises);

                const partnersWithStatistics = partnersData.map((partner, index) => ({
                    ...partner,
                    statistics: partnerStatisticsResponses[index]?.data || {}
                }));

                setPartners(partnersWithStatistics);
            } catch (error) {
                console.error(t('partnersTable.errorFetchingPartners'), error);
            }
        };
        fetchPartners();
    }, [filterText, sortDirection, filters, sortField, page, rowsPerPage, t]);

    const getStatusColor = (index) => {
        try {
            return statusOptions[index].color;
        } catch (e) {
            return 'neutral';
        }
    };

    const getStatusLabel = (index) => {
        try {
            return statusOptions[index].label;
        } catch (e) {
            return t('partnersTable.unknownStatus');
        }
    };

    const statusOptions = [
        { color: "negative", label: t('partnersTable.inactive'), value: 0 },
        { color: "positive", label: t('partnersTable.active'), value: 1 },
    ];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedPartners = filteredAndSortedPartners.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    const handleFilterChange = useCallback((name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value
        }));
    }, []);

    const resetFilters = useCallback(() => {
        setFilters({
            active: '',
        });
    }, []);

    const closeTag = (key) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [key]: ''
        }));
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const filterOptions = [
        { key: 'active', label: t('partnersTable.statusFilter'), defaultLabel: t('partnersTable.allStatuses'), options: statusOptions.map(option => ({ value: option.value.toString(), label: option.label })) },
    ];

    // Reset page when filterText or filters change
    useEffect(() => {
        setPage(0);
    }, [filterText, filters]);

    return (
        <div>
            <FilterModal
                open={modalOpen}
                handleClose={closeModal}
                filters={filters}
                filterOptions={filterOptions}
                handleFilterChange={handleFilterChange}
                resetFilters={resetFilters}
            />
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" colSpan={3}>
                                <Input
                                    type="text"
                                    placeholder={t('partnersTable.searchPlaceholder')}
                                    value={filterText}
                                    onChange={e => {
                                        setFilterText(e.target.value);
                                        setPage(0); // Reset to first page on search
                                    }}
                                    customWidth="160px"
                                    iconBefore={mdiMagnify}
                                />
                            </TableCell>
                            <TableCell align="left">
                                {filterOptions.map((filterOption) => (
                                    filters[filterOption.key] && (
                                        <Tag
                                            key={filterOption.key}
                                            className="tag-instance"
                                            hasCloseButton={true}
                                            label="Actif"//{statusOptions.find(option => option.value === filters[filterOption.key]).label}
                                            prefix={filterOption.label}
                                            states="default"
                                            onClickClose={e => closeTag(filterOption.key)}
                                        />
                                    )
                                ))}
                            </TableCell>

                            <TableCell align="left"></TableCell>
                            <TableCell align="left"></TableCell>
                            <TableCell align='right'>
                                <Button
                                    appearance="secondary"
                                    className="button-instance"
                                    hasAfterIcon={false}
                                    hasBeforeIcon={true}
                                    hasDropdownIndicator={false}
                                    intent="none"
                                    label={t('partnersTable.filterButton')}
                                    size="small"
                                    stateProp="normal"
                                    type="default"
                                    customBeforeIcon={mdiFilterVariant}
                                    onClick={openModal}
                                    width='80px'
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="left" sx={{ paddingTop: '0px', paddingBottom: '0px', paddingLeft: '8px', paddingRight: '8px', border: 0 }}><Icon path={mdiMenuSwap} size={1} onClick={() => setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')} /></TableCell>
                            <TableCell align="left" onClick={() => setSortField('name')}><span className='list-table-header'>{t('partnersTable.name')}</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>{t('partnersTable.status')}</span></TableCell>
                            <TableCell align="center"><span className='list-table-header'>{t('partnersTable.currentResponses')}</span></TableCell>
                            <TableCell align="center"><span className='list-table-header'>{t('partnersTable.wonResponses')}</span></TableCell>
                            <TableCell align="center"><span className='list-table-header'>{t('partnersTable.ongoingMissions')}</span></TableCell>
                            <TableCell align="left"><span className='list-table-header'>{t('partnersTable.mainContact')}</span></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {partners.map((row) => (
                            <TableRow
                                key={row.id}
                                hover={true}
                                onClick={() => navigate(`/partners/${row.id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}></TableCell>
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-title'>{row.name}</span>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <Status 
                                        className="status-instance" 
                                        color={getStatusColor(row.active ? 1 : 0)} // Assuming active is integer
                                        emphasis="strong" 
                                        label={getStatusLabel(row.active ? 1 : 0)} 
                                        style={{ width: '220px' }} 
                                    />
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className={`list-rfp-content ${row.statistics.applicationCountByStatus?.['1'] > 0 ? 'counter number' : ''}`}>
                                        {row.statistics.applicationCountByStatus?.['1'] || 0}
                                    </span>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className={`list-rfp-content ${row.statistics.applicationCountByStatus?.['4'] > 0 ? 'counter number' : ''}`}>
                                        {row.statistics.applicationCountByStatus?.['4'] || 0}
                                    </span>
                                </TableCell>
                                <TableCell align="center" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className={`list-rfp-content ${row.statistics.missionCount > 0 ? 'counter number' : ''}`}>
                                        {row.statistics.missionCount || 0}
                                    </span>
                                </TableCell>
                                <TableCell align="left" sx={{ padding: '0 8px', border: 0 }}>
                                    <span className='list-rfp-content'>{row.contact?.name}</span>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    className="custom-pagination"
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t('paginationLinesPerPage')}
                    ActionsComponent={TablePaginationActions}
                    labelDisplayedRows={({ from, to, count, page }) => {
                        const totalPages = Math.ceil(count / rowsPerPage);
                        return `${page + 1} / ${totalPages}`;
                    }}
                />
            </TableContainer>
        </div>
    );
}
