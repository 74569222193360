import PropTypes from "prop-types";
import React from "react";
import { MdiCheckCircleOutline } from "../../icons/MdiCheckCircleOutline";
import { MdiRadioboxMarked5 } from "../../icons/MdiRadioboxMarked5";
import "./style.css";

export const WizardItems = ({
  label = "Step Name",
  type,
  state,
  className,
  overlapGroupClassName,
  icon = <MdiRadioboxMarked5 className="mdi-radiobox-marked" color="white" />,
}) => {
  return (
    <div className={`wizard-items ${type} ${state} ${className}`}>
      <div className={`overlap-group ${overlapGroupClassName}`}>
        {type === "end" && ["active", "completed"].includes(state) && (
          <img className="bg" alt="Bg" src={state === "active" ? "/img/bg-15.svg" : "/img/bg-16.svg"} />
        )}

        <div className="text-icon">
          {state === "completed" && type === "end" && (
            <MdiCheckCircleOutline className="mdi-radiobox-marked" color="white" />
          )}

          {state === "active" && type === "end" && <MdiRadioboxMarked5 className="mdi-radiobox-marked" color="white" />}

          {type === "end" && ["active", "completed"].includes(state) && <div className="step-name">{label}</div>}

          {state === "inactive" && <div className="text-wrapper">{label}</div>}

          {((state === "active" && type === "initial") ||
            (state === "active" && type === "middle") ||
            (state === "completed" && type === "initial") ||
            (state === "completed" && type === "middle")) && (
            <>
              {icon}
              <div className="div">{label}</div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

WizardItems.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["end", "middle", "initial"]),
  state: PropTypes.oneOf(["completed", "inactive", "active"]),
};
