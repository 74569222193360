import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal, Box } from '@mui/material';
import { Button } from '../components/Button';
import { Input } from '../components/Input';
import { mdiPlus } from '@mdi/js';
import api from '../middlewares/api';
import { modalStyle } from '../utils/muiUtils';

const FeedbackForm = ({ open, handleClose, name, username }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState({
    currentUrl: window.location.href,
    username: '',
    name: '',
    title: '',
    body: '',
  });

  useEffect(() => {
    if (open) {
      setFormData({
        currentUrl: window.location.href,
        username: '',
        name: '',
        title: '',
        body: '',
      });
      setSuccess(false);
    }
  }, [open]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { currentUrl, title, body } = formData;
    if (title.length === 0 || body.length === 0) {
      return;
    }
    const issueData = {
      title: title,
      body: `**${t('feedbackForm.reportedBy')}** ${name} (${username})\n**${t('feedbackForm.url')}** ${currentUrl}\n\n${body}`,
      labels: ['feedback'],
    };

    try {
      const response = await api.post('/issue', issueData);

      if (response.data && response.data.message === t('feedbackForm.issueCreatedSuccess')) {
        setSuccess(true);
      } else {
        console.error(t('feedbackForm.errorCreatingIssue'), response.data);
      }
    } catch (error) {
      console.error(t('feedbackForm.errorCreatingIssue'), error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        {!success ? (
          <form onSubmit={handleSubmit}>
            <div className="dialog">
              <div className="primitives">
                <div className="text-wrapper">{t('feedbackForm.reportIssue')}</div>
                <div className="icon-button" onClick={handleClose}>
                  <img className="before-icon" src="/img/before-icon-2.svg" alt={t('feedbackForm.close')} />
                </div>
              </div>
              <div className="primitives">
                <input type="hidden" name="currentUrl" value={formData.currentUrl} />
                <input type="hidden" name="username" value={formData.username} />
                <input type="hidden" name="name" value={formData.name} />
                <div className="input-container-normal">
                  <div className="input-header-normal">{t('feedbackForm.title')}:</div>
                  <div className="input-content-normal">
                    <Input type="text" id="title" name="title" value={formData.title} onChange={handleChange} required />
                  </div>
                </div>
              </div>
              <div className="primitives">
                <div className="input-container-normal">
                  <div className="input-header-normal">{t('feedbackForm.description')}:</div>
                  <div className="input-content-normal width-fix">
                    <textarea className="textareaSK" id="body" name="body" rows={4} value={formData.body} onChange={handleChange} required />
                  </div>
                </div>
              </div>
              <div className="primitives-2">
                <div className="frame">
                  <Button
                    appearance="primary"
                    className="button-instance"
                    hasAfterIcon={false}
                    hasBeforeIcon={false}
                    hasDropdownIndicator={false}
                    intent="none"
                    label={t('feedbackForm.submit')}
                    size="small"
                    stateProp="normal"
                    type="default"
                    onClick={handleSubmit}
                    width="auto"
                    customAfterIcon={mdiPlus}
                    customBeforeIcon={mdiPlus}
                  />
                </div>
              </div>
            </div>
          </form>
        ) : (
          <div className="dialog">
            <div className="primitives">
              <div className="text-wrapper">{t('feedbackForm.reportIssue')}</div>
              <div className="icon-button" onClick={handleClose}>
                <img className="before-icon" src="/img/before-icon-2.svg" alt={t('feedbackForm.close')} />
              </div>
            </div>
            <div className="primitives-icon">
              <img className="mdi-check" src="/img/mdi-check-2.svg" alt={t('feedbackForm.success')} />
            </div>
            <div className="primitives">
              <p className="div">{t('feedbackForm.thankYou')}</p>
            </div>
            <div className="primitives-2">
              <div className="frame">
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="none"
                  label={t('feedbackForm.close')}
                  size="small"
                  stateProp="normal"
                  type="default"
                  onClick={handleClose}
                  width="auto"
                />
              </div>
            </div>
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default FeedbackForm;
