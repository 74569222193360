import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import FileUploadHandler from '../mycomponents/FileUploadHandler';
import { Input } from '../components/Input';
import { CardSkills } from '../mycomponents/CardSkills';
import { mdiFileCogOutline, mdiPlus, mdiAccountOutline, mdiBriefcaseCheckOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { arraysEqual } from '../utils/dataTools';
import { convertTimestampToDate } from '../utils/dateUtils';
import departements from '../utils/departements';
import PartnerNameById from './PartnerNameById';
import { Button } from '../components/Button';
import { Select } from './Select';
import { CardApplicantWorkExperience } from './CardApplicantWorkExperience/CardApplicantWorkExperience';
import { applicantSkillLevelOptions, getApplicantStatusLabel, applicantStatusOptions } from '../utils/StatusSkills';

const transformDepartements = (departements) => {
    return departements.map(departement => ({
        value: departement.num_dep.toString(),
        label: departement.dep_name,
        region_name: departement.region_name
    }));
};

const EditApplicant = ({ id, onUpdateApplicant }) => {
    const { t } = useTranslation();
    const auth = useAuth();
    const [defDep, setDefDep] = useState();
    const [formData, setFormData] = useState({
        partner_id: '',
        status: 0, // Initialize as integer index
        resume: '',
        label: '',
        rate: 0,
        last_name: '',
        first_name: '',
        trigraph: '',
        birth_date: '',
        birth_place: '',
        residence: '',
        languages: [],
        skills: [],
        diplomas: [],
        workexperiences: [] // Added to initial state
    });

    const [editingMode, setEditingMode] = useState(false);

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const responseApplicant = await api.get(`/applicant/${id}`);
                const fetchedData = responseApplicant.data;

                // Ensure 'status' is an integer index
                setFormData({
                    partner_id: fetchedData.partner_id || '',
                    status: fetchedData.status !== undefined ? parseInt(fetchedData.status, 10) : 0,
                    resume: fetchedData.resume || '',
                    label: fetchedData.label || '',
                    rate: fetchedData.rate || 0,
                    last_name: fetchedData.last_name || '',
                    first_name: fetchedData.first_name || '',
                    trigraph: fetchedData.trigraph || '',
                    birth_date: fetchedData.birth_date || '',
                    birth_place: fetchedData.birth_place || '',
                    residence: fetchedData.residence || '',
                    languages: fetchedData.languages || [],
                    skills: fetchedData.skills || [],
                    diplomas: fetchedData.diplomas || [],
                    workexperiences: fetchedData.workexperiences || []
                });

                const match = departements.find(departement => departement.num_dep.toString() === fetchedData.residence);
                setDefDep(match);
            } catch (error) {
                console.error(t('editApplicant.errorFetchingData'), error);
            }
        };

        fetchData();
    }, [id, t]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevFormData => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleSelectChange = (value) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            residence: value
        }));
    };

    const handleUploadSuccess = (data) => {
        setFormData(prevFormData => ({
            ...prevFormData,
            resume: data
        }));
        console.log(t('editApplicant.uploadedFileData'), data);
    };

    const handleSubmit = async (event) => {
        event?.preventDefault();
        console.log("Submitting formData:", formData); // Debugging line
        try {
            await api.put(`/applicant/${id}`, formData);
            console.log(t('editApplicant.applicantUpdated'));
        } catch (error) {
            console.error(t('editApplicant.errorUpdatingApplicant'), error);
        }
    };

    const [skillsData, setSkillsData] = useState(formData.skills);
    const handleSaveSkills = (updatedSkills) => {
        if (!arraysEqual(skillsData, updatedSkills)) {
            setSkillsData(updatedSkills);
            setFormData(prevFormData => ({
                ...prevFormData,
                skills: updatedSkills
            }));
            console.log(t('editApplicant.skillsUpdated'));
        }
    };

    const [diplomasData, setDiplomasData] = useState(formData.diplomas);
    const handleSaveDiplomas = (updatedDiplomas) => {
        if (!arraysEqual(diplomasData, updatedDiplomas)) {
            setDiplomasData(updatedDiplomas);
            setFormData(prevFormData => ({
                ...prevFormData,
                diplomas: updatedDiplomas
            }));
            console.log(t('editApplicant.diplomasUpdated'));
        }
    };

    const [languagesData, setLanguagesData] = useState(formData.languages);
    const handleSaveLanguages = (updatedLanguages) => {
        if (!arraysEqual(languagesData, updatedLanguages)) {
            setLanguagesData(updatedLanguages);
            setFormData(prevFormData => ({
                ...prevFormData,
                languages: updatedLanguages
            }));
            console.log(t('editApplicant.languagesUpdated'));
        }
    };

    const [experiencesData, setExperiencesData] = useState(formData.workexperiences);
    const handleSaveExperiences = (updatedExperiences) => {
        if (!arraysEqual(experiencesData, updatedExperiences)) {
            setExperiencesData(updatedExperiences);
            setFormData(prevFormData => ({
                ...prevFormData,
                workexperiences: updatedExperiences
            }));
            console.log(t('editApplicant.experiencesUpdated'));
        }
    };

    const setCollapsed = async () => {
        if (editingMode) {
            await handleSubmit();
        }
        setEditingMode(!editingMode);
    };

    const handleStatusChange = (value) => {
        // Ensure the value is an integer index
        const statusIndex = parseInt(value, 10);
        if (!isNaN(statusIndex)) {
            setFormData(prevFormData => ({
                ...prevFormData,
                status: statusIndex
            }));
        }
    };

    return (
        <div className='full-width'>
            <div className="columns-container">
                <div className="columnL">
                    <div>
                        <div className='card-component applicant-main width-fix'>
                            <div className="card-header-alt">
                                <div>
                                    <Icon
                                        path={mdiAccountOutline}
                                        color="#CED4DD"
                                        size="20px" />
                                    <span className="card-title">{t('editApplicant.information')}</span>
                                </div>
                                <div className="headr">
                                    <Button
                                        appearance="secondary"
                                        className="button-instance"
                                        hasAfterIcon={false}
                                        hasBeforeIcon={false}
                                        hasDropdownIndicator={false}
                                        intent="none"
                                        label={editingMode ? t('editApplicant.save') : t('editApplicant.edit')}
                                        size="small"
                                        stateProp="normal"
                                        type="default"
                                        customBeforeIcon={mdiAccountOutline}
                                        customAfterIcon={mdiPlus}
                                        onClick={setCollapsed}
                                    />
                                </div>
                            </div>
                            <form className='card-content-v-padding' onSubmit={handleSubmit}>
                                <div className="v-padding-fix">
                                    {/* New Status Field */}
                                    <div className="inputs-row">
                                        <div className="input-container-normal">
                                            <div className='input-header-normal'>
                                                Statuts :
                                            </div>
                                            <div className='drawer-formfield-inactive'>
                                                {editingMode ? (
                                                    <Select
                                                        name="status"
                                                        options={applicantStatusOptions.map((option, index) => ({
                                                            label: option.label,
                                                            value: index, // Use index as value
                                                            color: option.color
                                                        }))}
                                                        value={formData.status}
                                                        onChange={handleStatusChange}
                                                    />
                                                ) : (
                                                    getApplicantStatusLabel(formData.status)
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.cv')}:
                                            <FileUploadHandler onUploadSuccess={handleUploadSuccess} resumeData={formData.resume} editingMode={editingMode} />
                                        </div>
                                    </div>
                                </div>
                                {/* ... Rest of the form fields ... */}
                                <div className="inputs-row inputs-row-padfix">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.title')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="label"
                                                    value={formData.label}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.label}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.partner')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            <PartnerNameById id={formData.partner_id} type="partner" />
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.rate')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="number"
                                                    name="rate"
                                                    value={formData.rate}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode} />
                                            ) : `${formData.rate}€ HT/jour`}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.lastName')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="last_name"
                                                    value={formData.last_name}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.last_name}
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.firstName')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="first_name"
                                                    value={formData.first_name}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.first_name}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.birthDate')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="date"
                                                    name="birth_date"
                                                    value={formData.birth_date}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : convertTimestampToDate(formData.birth_date)}
                                        </div>
                                    </div>
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.birthPlace')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Input type="text"
                                                    name="birth_place"
                                                    value={formData.birth_place}
                                                    onChange={handleInputChange}
                                                    disabled={!editingMode}
                                                />
                                            ) : formData.birth_place}
                                        </div>
                                    </div>
                                </div>
                                <div className="inputs-row">
                                    <div className="input-container-normal">
                                        <div className='input-header-normal'>
                                            {t('editApplicant.residence')}:
                                        </div>
                                        <div className='drawer-formfield-inactive'>
                                            {editingMode ? (
                                                <Select
                                                    name="residence"
                                                    options={transformDepartements(departements)}
                                                    value={formData.residence}
                                                    onChange={handleSelectChange}
                                                />
                                            ) : (
                                                `${defDep?.dep_name} (${formData.residence})`
                                            )}

                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="columnR">
                    <div>
                        <CardSkills
                            skills={formData.skills}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveSkills}
                            title={t('editApplicant.skills')}
                            placeHolder={t('editApplicant.newSkill')}
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={formData.diplomas}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveDiplomas}
                            title={t('editApplicant.diplomas')}
                            placeHolder={t('editApplicant.newDiploma')}
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                            hasLevelSelect={false}
                        />
                    </div>
                    <div>
                        <CardApplicantWorkExperience
                            experiences={formData.workexperiences}
                            iconPath={mdiBriefcaseCheckOutline}
                            saveExperiences={handleSaveExperiences}
                            title={t('editApplicant.experiences')}
                            placeHolder={t('editApplicant.newExperience')}
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                        />
                    </div>
                    <div>
                        <CardSkills
                            skills={formData.languages}
                            iconPath={mdiFileCogOutline}
                            saveLanguages={handleSaveLanguages}
                            title={t('editApplicant.languages')}
                            placeHolder={t('editApplicant.newLanguage')}
                            editButton={true}
                            enabled={true}
                            handleSubmit={handleSubmit}
                            SkillLevelOptions={applicantSkillLevelOptions}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditApplicant;
