import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { mdiArrowLeft} from '@mdi/js';
import { IconButton } from '../components/IconButton';
import { useNavigate } from 'react-router-dom';
import EditApplicant from '../mycomponents/EditApplicant';
import { Status } from '../components/Status';
import {getApplicantStatusColor, getApplicantStatusLabel} from '../utils/StatusSkills';
const EditApplicantPage = () => {
    const auth = useAuth();
    const { id } = useParams(); // Get the id from URL params

    const [formData, setFormData] = useState({
        partner_id: '',
        status: '',
        resume: '',
        label: '',
        rate: 0,
        last_name: '',
        first_name: '',
        trigraph: '',
        birth_date: '',
        birth_place: '',
        residence: '',
        languages: [],
        skills: [],
        diplomas: []
    });
   
    const [error, setError] = useState(null);

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();  // Ensure cleanup is called on component unmount
        }
    }, [auth.initialized, auth.updateToken]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch applicant details
                const responseApplicant = await api.get(`/applicant/${id}`);
                setFormData(responseApplicant.data);    
                
            } catch (error) {
                console.error('Error fetching data:', error);
                setError(error.message);
            }
        };

        fetchData();
    }, [id]);

    const onUpdateApplicant = (updatedData) => {
        setFormData(updatedData);
    };
    const navigate = useNavigate();
    const handleClick = () => {
      navigate(-1);
    };
    return (
        <div>
            <div className="cardrfptitle">
                <div className="header">
                    <div onClick={handleClick}>
                        <IconButton
                            appearance="primary"
                            hasDropdownIndicator={false}
                            rounded={false}
                            size="small"
                            stateProp="normal"
                            type="outline"
                            customIcon={mdiArrowLeft}
                        />
                        <div>
                            <div className="rfptitle">
                                {formData.first_name} {formData.last_name}
                                <span className='h-padding-s'>
                                <Status className="status-instance"
                                    color={getApplicantStatusColor(formData.status)}
                                    emphasis="strong"
                                    label={getApplicantStatusLabel(formData.status)}
                                    style={{ width: '100px' }} />
                                </span>
                            </div>
               
                            <div>
                                <span className="rfptitle_strong_large">{formData.label}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className="headr">

                    </div>
                </div>
                <div>
                    {error && <p>Error: {error}</p>}
                </div>
                <EditApplicant id={id} onUpdateApplicant={onUpdateApplicant} />
            </div>
        </div>
    );
};



export default EditApplicantPage;
