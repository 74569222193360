import React, { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { CardClientHeader } from '../mycomponents/CardClientHeader';
import CardHeader from '../mycomponents/CardHeader';
import { CardRfpDates } from '../mycomponents/CardRfpDates';
import { CardRfpLocation } from '../mycomponents/CardRfpLocation';
import { CardRfpDesc } from '../mycomponents/CardRfpDesc';
import { CardRfpApplicants } from '../mycomponents/CardRfpApplicants';
import { CardSkills } from '../mycomponents/CardSkills';
import { mdiWeb, mdiFileCogOutline, mdiBriefcaseCheckOutline, mdiDomain } from '@mdi/js';
import { SkillLevelOptions } from '../utils/StatusSkills';
import { Select } from '../mycomponents/Select'; // Ensure this path is correct

const EditMissionPage = () => {
    const { t } = useTranslation();
    const auth = useAuth();
    const { id } = useParams(); // Get the mission ID from URL params

    // Initialize formData with status as integer
    const [formData, setFormData] = useState({
        status: 0, // Default status index
        client_name: '',
        client_id: '',
        prescriber: '',
        mission_title: '',
        offer_type: '',
        partner_name: '',
        partner_id: '',
        applicant_name: '',
        applicant_id: '',
        start_date: '',
        end_date: '',
        opt_start_date: '',
        opt_end_date: '',
        renewable: '',
        location_primary: '',
        location_secondary: '',
        remote: '',
        rate: '',
        documents: '',
        contracts: '',
        rfp_id: '',
    });

    const statusOptions = useMemo(() => [
        { value: 0, color: "neutral", label: "A venir" },
        { value: 1, color: "positive", label: "En cours" },
        { value: 2, color: "warning", label: "Terminée" },
        { value: 3, color: "negative", label: "Fin Anticipée" },
    ], []);

    const [error, setError] = useState(null);
    const [partnerName, setPartnerName] = useState('');
    const [rfpData, setRfpData] = useState({});
    const [statusUpdating, setStatusUpdating] = useState(false); // Track status update state

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();  // Ensure cleanup is called on component unmount
        }
    }, [auth.initialized, auth.updateToken]);

    useEffect(() => {
        if (id) {
            const fetchMissionData = async () => {
                try {
                    const response = await api.get(`/mission/${id}`); // Fetch mission data by ID
                    const fetchedData = response.data;

                    // Parse status as integer
                    const parsedStatus = fetchedData.status !== undefined ? parseInt(fetchedData.status, 10) : 0;

                    setFormData({
                        status: parsedStatus,
                        client_name: fetchedData.client_name || '',
                        client_id: fetchedData.client_id || '',
                        prescriber: fetchedData.prescriber || '',
                        mission_title: fetchedData.mission_title || '',
                        offer_type: fetchedData.offer_type || '',
                        partner_name: fetchedData.partner_name || '',
                        partner_id: fetchedData.partner_id || '',
                        applicant_name: fetchedData.applicant_name || '',
                        applicant_id: fetchedData.applicant_id || '',
                        start_date: fetchedData.start_date || '',
                        end_date: fetchedData.end_date || '',
                        opt_start_date: fetchedData.opt_start_date || '',
                        opt_end_date: fetchedData.opt_end_date || '',
                        renewable: fetchedData.renewable || '',
                        location_primary: fetchedData.location_primary || '',
                        location_secondary: fetchedData.location_secondary || '',
                        remote: fetchedData.remote || '',
                        rate: fetchedData.rate || '',
                        documents: fetchedData.documents || '',
                        contracts: fetchedData.contracts || '',
                        rfp_id: fetchedData.rfp_id || '',
                    });

                    await getPartnerById(fetchedData.partner_id);
                    const rfpResponse = await api.get(`/rfp/${fetchedData.rfp_id}`);
                    
                    setRfpData(rfpResponse.data);
                } catch (error) {
                    console.error('Error fetching mission data:', error);
                    setError(error.message);
                }
            };
            fetchMissionData();
        }
    }, [id]);

    const handleStatusChange = async (value) => {
        const previousStatus = formData.status;
        const updatedFormData = { ...formData, status: value };
        setFormData(updatedFormData);
        setStatusUpdating(true);

        try {
            await api.put(`/mission/${id}`, updatedFormData); // Update mission data with new status
            console.log('Status updated successfully');
        } catch (error) {
            console.error('Error updating status:', error);
            setError(error.message);
            // Revert to previous status on error
            setFormData(prevFormData => ({
                ...prevFormData,
                status: previousStatus
            }));
        } finally {
            setStatusUpdating(false);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setError(null);

        try {
            if (id) {
                await api.put(`/mission/${id}`, formData); // Update mission data using PUT method
                //console.log('Mission data updated successfully');
            } else {
                await api.post('/mission', formData); // Create new mission using POST method
                console.log('Mission created successfully');
            }
        } catch (error) {
            console.error('Error saving mission data:', error);
            setError(error.message);
        }
    };

    const getPartnerById = async (partner_id) => {
        try {
            const partnerResponse = await api.get(`/partner/${partner_id}`);
            setPartnerName(partnerResponse.data.name);
        }
        catch (error) {
            console.error('Error fetching partner data:', error);
            setError(error.message);
        }
    }

    return (
        <div>
          <CardClientHeader
            rfp_title={`${formData.client_name} - ${partnerName} - ${formData.applicant_name}`}
            client_name={formData.client_name}
            client_ref={t('clientHeader.clientRef')}
            eq_ref={t('clientHeader.eqRef')}
            rfp_id={`${id}`}
          />
          {error && <div>{t('common.error')}: {error}</div>}
      
          <div className="columns-container">
            <div className="columnL">
              <div className="card-component">
                <CardHeader
                  context="editor"
                  editButton={false}
                  iconPath={mdiDomain}
                  title={t('mission.title')}
                />
                <div className="card-content">
                  <div className="inputs-row">
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.name')}:</div>
                      <div className="drawer-formfield-inactive">
                        {formData.mission_title}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-row">
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.clientName')}:</div>
                      <div className="drawer-formfield-inactive">
                        {formData.client_name}
                      </div>
                    </div>
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.market')}:</div>
                      <div className="drawer-formfield-inactive">
                        {rfpData.client_mkt}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-row">
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.geContractRef')}:</div>
                      <div className="drawer-formfield-inactive">
                        {formData.status}
                      </div>
                    </div>
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.clientBdcRef')}:</div>
                      <div className="drawer-formfield-inactive">
                        {formData.client_id}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-row">
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.eqRef')}:</div>
                      <div className="drawer-formfield-inactive">
                        {rfpData.eq_ref}
                      </div>
                    </div>
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.clientRef')}:</div>
                      <div className="drawer-formfield-inactive">
                        {rfpData.client_ref}
                      </div>
                    </div>
                  </div>
                  <div className="inputs-row">
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.offerType')}:</div>
                      <div className="drawer-formfield-inactive">
                        {formData.offer_type}
                      </div>
                    </div>
                    <div className="input-container-normal">
                      <div className="input-header-normal">{t('mission.estimatedAmount')}:</div>
                      <div className="drawer-formfield-inactive">
                        {rfpData.amount}&nbsp;€ HT
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CardRfpDates
                start_date={formData.start_date}
                end_date={formData.end_date}
                opt_start_date={formData.opt_start_date}
                opt_end_date={formData.opt_end_date}
                isAdmin={false}
                editButton={false}
                context="mission"
              />
              <CardRfpLocation
                location_1={formData.location_primary}
                location_2={formData.location_secondary}
                remote={formData.remote}
                isAdmin={false}
                editButton={false}
                context="mission"
              />
            </div>
            <div className="columnR">
              <CardRfpApplicants rfp_id={formData.rfp_id} />
              <CardRfpDesc
                description={rfpData.full_desc}
                summary={rfpData.summ_desc}
                editing={false}
                handleSubmit={handleSubmit}
                isAdmin={false}
                editButton={false}
              />
              <div>
                <CardSkills
                  skills={rfpData.skills}
                  iconPath={mdiFileCogOutline}
                  title={t('mission.skills')}
                  placeHolder={t('mission.skillPlaceholder')}
                  editButton={false}
                  enabled={true}
                  collapsed={true}
                  SkillLevelOptions={SkillLevelOptions}
                  hasLevelSelect={true}
                  isAdmin={false}
                />
              </div>
              <div>
                <CardSkills
                  skills={rfpData.languages}
                  iconPath={mdiWeb}
                  title={t('mission.languages')}
                  placeHolder={t('mission.languagePlaceholder')}
                  editButton={false}
                  SkillLevelOptions={SkillLevelOptions}
                  hasLevelSelect={true}
                  isAdmin={false}
                />
              </div>
              <div>
                <CardSkills
                  skills={formData.diplomas}
                  iconPath={mdiBriefcaseCheckOutline}
                  title={t('mission.diplomas')}
                  placeHolder={t('mission.diplomaPlaceholder')}
                  editButton={false}
                  SkillLevelOptions={SkillLevelOptions}
                  hasLevelSelect={true}
                />
              </div>
            </div>
          </div>
        </div>
      );
      
}

export default EditMissionPage;
