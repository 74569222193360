import React from "react";
import { useTranslation } from 'react-i18next';
import { mdiArrowLeft, mdiAccountOutline, mdiPlus } from '@mdi/js';
import { IconButton } from '../../components/IconButton';
import { Button } from "../../components/Button";
import "./style.css";
import { useNavigate } from 'react-router-dom';

export const CardRfpTitle = ({
  client_name = "client_name",
  rfp_title,
  client_ref,
  eq_ref,
  rfp_id,
  toggleEditAndUpdate,
  isEditing,
  updateStatus,
  finalize,
  status,
  isAdmin = false,
  roles = [],
  context = 'default',
  createMission,
  complete = false
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(-1);
  };

  const goToProposal = () => {
    navigate(`/proposal/${rfp_id}`);
  };

  if (roles.includes('client')) {
    return (
      <div className="cardrfptitle">
        <div className="header">
          <div onClick={handleClick}>
            <IconButton
              appearance="primary"
              hasDropdownIndicator={false}
              rounded={false}
              size="small"
              stateProp="normal"
              type="outline"
              customIcon={mdiArrowLeft}
            />
            <div>
              <div className="rfptitle">{rfp_title}</div>
              <div>
                <span className="rfptitle_client_ref">{client_ref}</span>
                <span className="rfptitle_strong_large"> {client_name}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="cardrfptitle">
        <div className="header">
          <div onClick={handleClick}>
            <IconButton
              appearance="primary"
              hasDropdownIndicator={false}
              rounded={false}
              size="small"
              stateProp="normal"
              type="outline"
              customIcon={mdiArrowLeft}
            />
            <div>
              <div className="rfptitle">{rfp_title}</div>
              <div>
                <span className="rfptitle_client_ref">{client_ref}</span>
                <span className="rfptitle_strong_large"> {client_name}</span>
              </div>
            </div>
          </div>
          <div className="headr">
            {status === 0 && context === 'default' && (
              <Button
                appearance="secondary"
                className="button-instance"
                label={t('cardRfpTitle.review')}
                hasAfterIcon={false}
                hasBeforeIcon={false}
                hasDropdownIndicator={false}
                intent="error"
                size="medium-default"
                stateProp="normal"
                type="default"
                customBeforeIcon={mdiAccountOutline}
                customAfterIcon={mdiPlus}
                onClick={() => updateStatus({ status: 1 })}
              />
            )}
            {status === 1 && context === 'default' && (
              <Button
                appearance="secondary"
                className="button-instance"
                label={t('cardRfpTitle.publish')}
                intent="error"
                size="medium-default"
                stateProp="normal"
                type="default"
                customBeforeIcon={mdiAccountOutline}
                customAfterIcon={mdiPlus}
                onClick={() => updateStatus({ status: 2 })}
                hasAfterIcon={false}
                hasBeforeIcon={false}
                hasDropdownIndicator={false}
              />
            )}
            {status === 2 && context === 'default' && (
              <>
                {isAdmin && (
                  <Button
                    appearance="secondary"
                    className="button-instance"
                    label={t('cardRfpTitle.close')}
                    intent="error"
                    size="medium-default"
                    stateProp="normal"
                    type="default"
                    customBeforeIcon={mdiAccountOutline}
                    customAfterIcon={mdiPlus}
                    onClick={() => updateStatus({ status: 3 })}
                    hasAfterIcon={false}
                    hasBeforeIcon={false}
                    hasDropdownIndicator={false}
                  />
                )}
                <Button
                  appearance="primary"
                  className="button-instance"
                  label={t('cardRfpTitle.respond')}
                  intent="positive"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  onClick={goToProposal}
                  hasAfterIcon={true}
                  hasBeforeIcon={true}
                  hasDropdownIndicator={false}
                />
              </>
            )}
            {status === 3 && context === 'default' && (
              <Button
                appearance="secondary"
                className="button-instance"
                label={t('cardRfpTitle.reopen')}
                intent="error"
                size="medium-default"
                stateProp="normal"
                type="default"
                customBeforeIcon={mdiAccountOutline}
                customAfterIcon={mdiPlus}
                onClick={() => updateStatus({ status: 0 })}
                hasAfterIcon={false}
                hasBeforeIcon={false}
                hasDropdownIndicator={false}
              />
            )}
            {status === 4 && context === 'default' && !window.location.pathname.includes('/proposal/') && (
              <>
                <Button
                  appearance="secondary"
                  className="button-instance"
                  label={t('cardRfpTitle.cancelMission')}
                  intent="error"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus({ status: 0 })}
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  label={t('cardRfpTitle.sendMission')}
                  hasAfterIcon={true}
                  hasBeforeIcon={true}
                  hasDropdownIndicator={false}
                  intent="positive"
                  size="medium-default"
                  stateProp={complete ? "normal" : "disabled"}
                  type="default"
                  onClick={createMission}
                />
              </>
            )}
            {status === 7 && context === 'default' && (
              <>
                <Button
                  appearance="secondary"
                  className="button-instance"
                  label={t('cardRfpTitle.previous')}
                  intent="none"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={() => updateStatus(2)}
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                />
                <Button
                  appearance="primary"
                  className="button-instance"
                  label={t('cardRfpTitle.sendProposal')}
                  intent="none"
                  size="medium-default"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={mdiAccountOutline}
                  customAfterIcon={mdiPlus}
                  onClick={finalize}
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                />
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
};
