import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { mdiFileDocumentOutline } from '@mdi/js';
import CardHeader from '../CardHeader';
import "./style.css";

export const CardRfpDesc = ({
  description: initialDesc,
  summary: initialSummary,
  deliverables: initialDeliverables,
  onDescriptionChange,
  handleSubmit,
  editButton
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [descriptions, setDescriptions] = useState({
    description: initialDesc,
    summary: initialSummary,
    deliverables: initialDeliverables
  });

  const initialDescriptionsRef = useRef({
    description: initialDesc,
    summary: initialSummary,
    deliverables: initialDeliverables
  });

  useEffect(() => {
    setDescriptions({
      description: initialDesc,
      summary: initialSummary,
      deliverables: initialDeliverables,
    });
  }, [initialDesc, initialSummary, initialDeliverables]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newDescriptions = { ...descriptions, [name]: value };
    setDescriptions(newDescriptions);
    if (onDescriptionChange) {
      onDescriptionChange(newDescriptions);
    }
  };

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();
    }
    setIsEditing(!isEditing);
  };

  const handleDiscard = () => {
    setDescriptions(initialDescriptionsRef.current);
    if (onDescriptionChange) {
      onDescriptionChange(initialDescriptionsRef.current);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="card-component">
      <CardHeader
        isEditing={!isEditing}
        context="editor"
        editButton={editButton}
        iconPath={mdiFileDocumentOutline}
        title={t('cardRfpDesc.title')}
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
      <div className="card-content card-content-v-padding">
        <div>
          <div className="card-label">
            {t('cardRfpDesc.missionDescription')}:
          </div>
          <div className="card-maincontent">
            {isEditing ? (
              <textarea
                className="textareaSK"
                name="summary"
                value={descriptions.summary}
                onChange={handleInputChange}
                rows={4}
                cols={300}>
              </textarea>
            ) : descriptions.summary}
          </div>
        </div>
        <div>
          <div className="card-label">
            {t('cardRfpDesc.deliverables')}:
          </div>
          <div className="card-maincontent">
            {isEditing ? (
              <textarea
                className="textareaSK"
                name="description"
                value={descriptions.description}
                onChange={handleInputChange}
                rows={8}
                cols={300}>
              </textarea>
            ) : descriptions.description}
          </div>
        </div>
      </div>
    </div>
  );
};
