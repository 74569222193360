import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import Icon from '@mdi/react';
import { mdiCalendarAlertOutline } from '@mdi/js';
import { FileUploader } from "react-drag-drop-files";

function FileUploadHandler({ onUploadSuccess, resumeData = {}, editingMode }) {
    const { t } = useTranslation();
    const auth = useAuth();
    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();  // Ensure cleanup is called on component unmount
        }
    }, [auth.initialized, auth.updateToken]);

    const handleFileChange = (inFile) => {
        console.log(inFile);
        const file = inFile;
        const allowedTypes = [
            'application/pdf',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'text/plain',
            'application/rtf',
            'application/vnd.oasis.opendocument.text'
        ];

        if (!file) {
            console.log("No file selected.");
            return;
        }
        if (!allowedTypes.includes(file.type)) {
            alert('Veuillez choisir un fichier PDF, DOCX, TXT, RTF, ou ODT.');
            return;
        }

        if (file.size > 1024 * 1024 * 5) {
            alert('Le fichier ne doit pas dépasser 5 Mo.');
            return;
        }

        const formData = new FormData();
        formData.append('file', file);

        // Send the file to the server
        api.post('/files/upload', formData)
            .then((response) => {
                // File upload successful
                onUploadSuccess(response.data); // Call the callback with the response data
            })
            .catch((error) => {
                // File upload failed
                console.log(error);
            });
    };

    const handleFileDownload = async (uuid, name) => {
        try {
            const response = await api.get(`/files/download/${uuid}`, {
                responseType: 'blob'
            });
            const blob = new Blob([response.data], { type: response.data.type });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = name;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("File download failed", error);
        }
    };

    const fileTypes = ["Pdf", "doc", "Docx", "Txt", "rtf", "odt"];

    return (
        <div className="file-upload-handler">
            <div className='resume-box'>
                {editingMode ? (
                    <FileUploader
                        multiple={false}
                        handleChange={handleFileChange}
                        name="file"
                        types={fileTypes}
                        classes="drop_area"

                    >
                        <div className="custom-drop-zone flex-fix resume-box-padding">
                        <Icon path={mdiCalendarAlertOutline} size="20px" className='resume-icon' />
                            <p>{t('fileUpload.main')}</p>
                        </div>
                    </FileUploader>
                ) : (<div className='custom-drop-zone flex-fix resume-box-padding'>
                    <div >
                        <Icon path={mdiCalendarAlertOutline} size="20px" className='resume-icon' />
                        {!resumeData || (!resumeData.uuid && !editingMode) ? (
                            <div>{t('fileUpload.addFile')}</div>
                        ) : null}
                    </div>
                     {resumeData && resumeData.uuid && (
                        <div onClick={() => handleFileDownload(resumeData.uuid, resumeData.name)} className="file-link resume-box-padding">
                            {resumeData.name}
                        </div>
                    )}
                </div>)}
               
            </div>
            <div className="file-info">
                {resumeData && resumeData.uuid && editingMode &&(
                        <div  className="file-link">
                            {t('fileUpload.currentFile')} {resumeData.name}
                        </div>
                    )}
            </div>
        </div>
    );
}

FileUploadHandler.propTypes = {
    onUploadSuccess: PropTypes.func.isRequired,
    resumeData: PropTypes.object,
    editingMode: PropTypes.bool,
};

export default FileUploadHandler;
