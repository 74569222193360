import "./style.css";
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { mdiBadgeAccountHorizontalOutline } from '@mdi/js';
import api, { setupInterceptors } from '../../middlewares/api';
import { useAuth } from '../../middlewares/useAuth';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import ApplicationDetails from '../ApplicationDetails';
import { Status } from "../../components/Status";
import { convertTimestampToDate } from '../../utils/dateUtils';
import { statusOptions } from '../../utils/rfpStatusOptions';
import CardHeader from '../CardHeader';

export const CardRfpApplicants = ({ rfp_id }) => {
  const { t } = useTranslation();
  const auth = useAuth();

  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe(); // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);

  const fetchApplicationsRef = useRef(null);
  const [applications, setApplications] = useState([]);
  const [partners, setPartners] = useState({});
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await api.get(`/rfp/applications/${rfp_id}`);
        setApplications(response.data);

        const partnerIds = response.data.map((row) => row.partner_id);
        const partnerResponses = await Promise.all(partnerIds.map((partnerId) => api.get(`/partner/${partnerId}`)));
        const fetchedPartners = partnerResponses.reduce((acc, partnerResponses) => {
          acc[partnerResponses.data.id] = partnerResponses.data.name;
          return acc;
        }, {});

        setPartners(fetchedPartners);
      } catch (error) {
        console.error(t('cardRfpApplicants.errorFetchingApplications'), error);
      }
    };

    fetchApplicationsRef.current = fetchApplications;
    fetchApplicationsRef.current();
  }, [rfp_id, refresh]);

  const openDrawer = (applicationId) => {
    setSelectedApplication(applicationId);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedApplication(null);
    setDrawerOpen(false);
  };

  const handleSave = async () => {
    closeDrawer();
    if (fetchApplicationsRef.current) {
      await fetchApplicationsRef.current();
    }
    setRefresh(refresh + 1);
  };

  const totalRate = (rate, partner_margin_rate, client_margin_rate) => {
    const numericRate = parseFloat(rate) || 0;
    const calculateCommission = (marginRate) => {
      return ((marginRate / 100) * numericRate).toFixed(2);
    };

    const partnerCommission = parseFloat(calculateCommission(parseFloat(partner_margin_rate)));
    const clientCommission = parseFloat(calculateCommission(parseFloat(client_margin_rate)));
    const totalCommission = partnerCommission + clientCommission;

    return (numericRate + totalCommission).toFixed(2);
  };

  const getStatusColor = (index) => {
    try {
      return statusOptions[index].color;
    } catch (e) {
      return 'neutral';
    }
  };

  const getStatusLabel = (index) => {
    try {
      return statusOptions[index].label;
    } catch (e) {
      return t('cardRfpApplicants.statusNotAvailable');
    }
  };

  return (
    <div className="card-component">
      <CardHeader
        isEditing={false}
        context="editor"
        editButton={false}
        iconPath={mdiBadgeAccountHorizontalOutline}
        title={t('cardRfpApplicants.title')}
      />
      <div >
        <div className="card-maincontent">
          <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
            {applications.length === 0 ? (
              <p>{t('cardRfpApplicants.noApplications')}</p>
            ) : (
              <Table sx={{ minWidth: 670, border: 'none' }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tabheader">
                    <TableCell align="left"><span className="list-table-header">{t('cardRfpApplicants.columnTrigraph')}</span></TableCell>
                    <TableCell align="left"><span className="list-table-header">{t('cardRfpApplicants.columnPartner')}</span></TableCell>
                    <TableCell align="left"><span className="list-table-header">{t('cardRfpApplicants.columnRate')}</span></TableCell>
                    <TableCell align="left"><span className="list-table-header">{t('cardRfpApplicants.columnStatus')}</span></TableCell>
                    <TableCell align="left"><span className="list-table-header">{t('cardRfpApplicants.columnCreatedDate')}</span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applications.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => openDrawer(row.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell align="left"><span className="list-rfp-content">{row.trigraph}</span></TableCell>
                      <TableCell align="left"><span className="list-rfp-content">{partners[row.partner_id]}</span></TableCell>
                      <TableCell align="left">
                        <span className="list-rfp-content">
                          {totalRate(
                            row.rate || '0',
                            row.offer_details.partner_margin_rate || '0',
                            row.offer_details.client_margin_rate || '0'
                          )} €
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <Status
                          className="status-instance"
                          color={getStatusColor(row.status)}
                          emphasis="strong"
                          label={getStatusLabel(row.status)}
                          style={{ width: '100px' }}
                        />
                      </TableCell>
                      <TableCell align="left"><span className="list-rfp-content">{convertTimestampToDate(row.createdAt)}</span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
      >
        {selectedApplication && (
          <div>
            <ApplicationDetails applicationId={selectedApplication} onSave={handleSave} />
          </div>
        )}
      </Drawer>
    </div>
  );
};
