import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { mdiTarget } from '@mdi/js';
import { Input } from "../../components/Input";
import { Select } from '../Select';
import { selectRemoteOptions } from '../../utils/selectOptionsUtils';
import CardHeader from '../CardHeader';
import "./style.css";

export const CardRfpLocation = ({
  location_1: initialLocation1,
  location_2: initialLocation2,
  remote: initialRemote,
  onLocationsChange,
  handleSubmit,
  editButton
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [locations, setLocations] = useState({
    location_1: initialLocation1,
    location_2: initialLocation2,
    remote: initialRemote,
  });

  const initialLocationsRef = useRef({
    location_1: initialLocation1,
    location_2: initialLocation2,
    remote: initialRemote,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const newLocations = { ...locations, [name]: value };
    setLocations(newLocations);
    if (onLocationsChange) {
      onLocationsChange(newLocations);
    }
  };

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();
    }
    setIsEditing(!isEditing);
  };

  const handleRemoteOptionChange = (newValue) => {
    const newLocations = { ...locations, remote: newValue };
    setLocations(newLocations);
    onLocationsChange(newLocations);
  };

  useEffect(() => {
    setLocations({
      location_1: initialLocation1,
      location_2: initialLocation2,
      remote: initialRemote,
    });
  }, [initialLocation1, initialLocation2, initialRemote]);

  const handleDiscard = () => {
    setLocations(initialLocationsRef.current);
    if (onLocationsChange) {
      onLocationsChange(initialLocationsRef.current);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="card-component">
      <CardHeader
        isEditing={!isEditing}
        context="editor"
        editButton={editButton}
        iconPath={mdiTarget}
        title={t('cardRfpLocation.title')}
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
      <div className="card-content card-content-v-padding">
        <div className="card-languages-list">
          <div className="card-label">
            {t('cardRfpLocation.primaryLocation')}:
          </div>
          <div className="maincontent">
            {isEditing ? (
              <Input
                type="text"
                name="location_1"
                value={locations.location_1}
                onChange={handleInputChange}
                className="input-normal"
                required
              />
            ) : (
              locations.location_1
            )}
          </div>
        </div>
        {(locations.location_2 || isEditing) && (
          <div className="card-languages-list">
            <div className="card-label">
              {t('cardRfpLocation.secondaryLocation')}:
            </div>
            <div className="maincontent">
              {isEditing ? (
                <Input
                  type="text"
                  name="location_2"
                  value={locations.location_2}
                  onChange={handleInputChange}
                  className="input-normal"
                />
              ) : (
                locations.location_2
              )}
            </div>
          </div>
        )}
        {(locations.remote || isEditing) && (
          <div className="card-languages-list">
            <div className="card-label">
              {t('cardRfpLocation.remote')}:
            </div>
            <div className="maincontent">
              {isEditing ? (
                <Select
                  name="remote"
                  options={selectRemoteOptions}
                  value={locations.remote}
                  onChange={handleRemoteOptionChange}
                  width="100%"
                />
              ) : (
                locations.remote
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
