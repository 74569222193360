import React from 'react';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { Button } from '../components/Button';
import { IconButton } from '../components/IconButton';
import {mdiPencilOutline } from '@mdi/js';

const CardHeader = ({ isEditing, context, editButton, iconPath, title, handleDiscard, toggleEdit }) => {
  const { t } = useTranslation();
  return (
    <div>
         {context==='editor' ?(
        <div className="card-header">
        <div>
          <Icon
            path={iconPath}
            color="#CED4DD"
            size="20px" />
          <span className="card-title">{title}</span>
        </div>
          <div className=" card-header-buttons">
            {(editButton && !isEditing) ? (
              <>
              <Button
                appearance="secondary"
                className="button-instance"
                hasAfterIcon={false}
                hasBeforeIcon={false}
                hasDropdownIndicator={false}
                intent="error"
                label={t('cardHeader.cancel')}
                size="small"
                stateProp="normal"
                type="default"
                customBeforeIcon={iconPath}
                customAfterIcon={iconPath}
                onClick={handleDiscard}
              />
                <Button
                  appearance="primary"
                  className="button-instance"
                  hasAfterIcon={false}
                  hasBeforeIcon={false}
                  hasDropdownIndicator={false}
                  intent="positive"
                  label={t('cardHeader.save')}
                  size="small"
                  stateProp="normal"
                  type="default"
                  customBeforeIcon={iconPath}
                  customAfterIcon={iconPath}
                  onClick={toggleEdit}
                />
              </>
            ) : (
              <>
              {editButton && (
                    <IconButton
                    appearance="primary"
                    hasDropdownIndicator={false}
                    rounded={false}
                    size="small"
                    stateProp="normal"
                    type="default"
                    customIcon={mdiPencilOutline}
                    onClick={toggleEdit}
                    />
                )}
              </>
            )}
          </div>
      </div>
      ):(
        <div className='header-xsmall'>
        {title}
      </div>
      )}   
  </div>
    )
};

export default CardHeader;
