import React, { useState, useEffect } from 'react'; 
import { useTranslation } from 'react-i18next';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { CardClientHeader } from '../mycomponents/CardClientHeader';
import Icon from '@mdi/react';
import { mdiAccountOutline, mdiPlus, mdiTextBoxOutline } from '@mdi/js';
import { Button } from '../components/Button';
import { Input } from "../components/Input";
import { convertTimestampToDate } from '../utils/dateUtils';

const CompanyPage = () => {
  const { t } = useTranslation();
  const auth = useAuth();

  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);

  const id = auth.partner_id ? auth.partner_id : ''; // Ensure `id` is set correctly
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    vat_nbr: '',
    siren: '',
    rcs: '',
    legal_status: '',
    category: '',
    capital: 0,
    phone_number: '',
    web_site: '',
    address: '',
    billing_address: { usemain: false, address: '' },
    bank_details: '',
    usemain:'',
    contact: {}, // Initialize contact
    labels: {}, // Initialize labels
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [editingMode, setEditingMode] = useState(false); // State to track editing mode
  const [collapsed, setCollapsed] = useState(true); // State to manage collapsed/expanded view

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
    setEditingMode(!editingMode);
  };

  useEffect(() => {
    const fetchPartnerData = async () => {
      try {
        const response = await api.get(`/partner/${id}`); // Fetch partner data by ID
        setFormData(response.data); // Populate form fields with fetched partner data
      } catch (error) {
        console.error('Error fetching partner data:', error);
        setError(error.message);
      }
    };

    if (id) {
      fetchPartnerData();
    }
  }, [id]);

  const handleInputChange = (event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === 'checkbox' ? checked : value;
    const updatedFormData = name.startsWith('billing_address.') // Check if it's a nested property
      ? { ...formData, billing_address: { ...formData.billing_address, [name.split('.')[1]]: newValue } }
      : { ...formData, [name]: newValue };
    setFormData(updatedFormData);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(null);

    try {
      await api.put(`/partner/${id}`, formData); // Update partner data using PUT method
      console.log('Partner data updated successfully');
    } catch (error) {
      console.error('Error updating partner data:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <CardClientHeader rfp_title={formData.name} client_name={formData.name} client_ref="CLREF" eq_ref="EQREF" rfp_id={`${id}`} />
      {error && <div>Error: {error}</div>}

      <div className="card-component">
        <div className="card-header">
          <div>
            <Icon
              path={mdiTextBoxOutline}
              color="#CED4DD"
              size="20px" />
            <span className="card-title">{t('companyPage.generalInfo')}</span>
          </div>
          <div className="headr" onClick={toggleCollapsed}>
            <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label={t('companyPage.edit')}
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
            />
          </div>
        </div>
        <div className="card-content">
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.name')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                ) : formData.name}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.legalStatus')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <select
                    id="legal_status"
                    name="legal_status"
                    value={formData.legal_status}
                    onChange={handleInputChange}
                    className='input-normal'
                    disabled={!editingMode}
                  >
                    <option value="">Select Legal Status</option>
                    <option value="EI(RL)">EI(RL)</option>
                    <option value="EURL">EURL</option>
                    <option value="SASU">SASU</option>
                    <option value="SA">SA</option>
                    <option value="SARL">SARL</option>
                    <option value="SAS">SAS</option>
                    <option value="SCA">SCA</option>
                    <option value="SCS">SCS</option>
                    <option value="SNC">SNC</option>
                  </select>
                ) : formData.legal_status}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.siren')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="siren"
                    value={formData.siren}
                    onChange={handleInputChange}
                  />
                ) : formData.siren}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.vatNumber')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="vat_nbr"
                    value={formData.vat_nbr}
                    onChange={handleInputChange}
                  />
                ) : formData.vat_nbr}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.rcsCity')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="rcs"
                    className='input-normal'
                    value={formData.rcs}
                    onChange={handleInputChange}
                  />
                ) : formData.rcs}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.companyCategory')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <select
                    id="category"
                    name="category"
                    value={formData.category}
                    onChange={handleInputChange}
                    className='input-normal'
                    disabled={!editingMode}
                  >
                    <option value="">Select Category</option>
                    <option value="Microentreprises (MIC)">Microentreprises (MIC)</option>
                    <option value="Petites et moyennes entreprises (PME)">Petites et moyennes entreprises (PME)</option>
                    <option value="Entreprises de taille intermédiaire (ETI)">Entreprises de taille intermédiaire (ETI)</option>
                    <option value="Grandes entreprises (GE)">Grandes entreprises (GE)</option>
                  </select>
                ) : formData.category}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.yearIncorporated')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="number"
                    name="year_incorporated"
                    value={formData.year_incorporated}
                    onChange={handleInputChange}
                  />
                ) : formData.year_incorporated}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.capital')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="capital"
                    className='input-normal'
                    value={formData.capital}
                    onChange={handleInputChange}
                  />
                ) : formData.capital}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-component">
        <div className="card-header">
          <div>
            <Icon
              path={mdiTextBoxOutline}
              color="#CED4DD"
              size="20px" />
            <span className="card-title">{t('companyPage.labelsAndCertifications')}</span>
          </div>
          <div className="headr" onClick={toggleCollapsed}>
            <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label="Editer"
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
            />
          </div>
        </div>
        <div className="card-content">
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.subcontracting')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="number"
                    name="sub_contracting"
                    className='input-normal'
                    value={formData.sub_contracting}
                    onChange={handleInputChange}
                  />
                ) : formData.sub_contracting}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.labels')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="labels.labels"
                    value={formData.labels?.labels}
                    onChange={handleInputChange}
                  />
                ) : formData.labels?.labels}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.responsibleSupplier')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="labels.corporate_responsibility"
                    value={formData.labels?.corporate_responsibility}
                    onChange={handleInputChange}
                  />
                ) : formData.labels?.corporate_responsibility}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.diversityCharter')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="labels.dei"
                    value={formData.labels?.dei}
                    onChange={handleInputChange}
                  />
                ) : formData.labels?.dei}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.callToMediation')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="labels.mediation"
                    value={formData.labels?.mediation}
                    onChange={handleInputChange}
                  />
                ) : formData.labels?.mediation}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
               {t('companyPage.disabledWorkers')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="labels.disabled"
                    value={formData.labels?.disabled}
                    onChange={handleInputChange}
                  />
                ) : formData.labels?.disabled}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.esatEa')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="labels.esat_ea"
                    value={formData.labels?.esat_ea}
                    onChange={handleInputChange}
                  />
                ) : formData.labels?.esat_ea}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-component">
        <div className="card-header">
          <div>
            <Icon
              path={mdiTextBoxOutline}
              color="#CED4DD"
              size="20px" />
            <span className="card-title">{t('companyPage.mainContact')}</span>
          </div>
          <div className="headr" onClick={toggleCollapsed}>
            <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label={t('companyPage.edit')}
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
            />
          </div>
        </div>
        <div className="card-content">
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.contactName')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="contact.name"
                    value={formData.contact?.name || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.contact?.name}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.contactEmail')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="email"
                    name="contact.email"
                    value={formData.contact?.email || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.contact?.email}
              </div>
            </div>      
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.newsletter')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="checkbox"
                    name="newsletter"
                    className='input-normal'
                    checked={formData.newsletter}
                    onChange={handleInputChange}
                  />
                ) : formData.newsletter ? 'Oui' : 'Non'}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-component">
        <div className="card-header">
          <div>
            <Icon
              path={mdiTextBoxOutline}
              color="#CED4DD"
              size="20px" />
            <span className="card-title">{t('companyPage.expertise')}</span>
          </div>
          <div className="headr" onClick={toggleCollapsed}>
            <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label="Editer"
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
            />
          </div>
        </div>
        <div className="card-content">
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.expertiseArea')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="expertise.area"
                    className='input-normal'
                    value={formData.expertise?.area || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.expertise?.area}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.technicalExpertise')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="expertise.tech"
                    className='input-normal'
                    value={formData.expertise?.tech || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.expertise?.tech}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.notedSpeciality')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="noted_speciality"
                    className='input-normal'
                    value={formData.noted_speciality || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.noted_speciality}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-component">
        <div className="card-header">
          <div>
            <Icon
              path={mdiTextBoxOutline}
              color="#CED4DD"
              size="20px" />
            <span className="card-title">{t('companyPage.contactInfo')}</span>
          </div>
          <div className="headr" onClick={toggleCollapsed}>
            <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label="Editer"
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
            />
          </div>
        </div>
        <div className="card-content">
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.address')} 
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="address.line1"
                    className='input-normal'
                    value={formData.address?.line1 || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.address?.line1}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.addressComplement')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="address.line2"
                    className='input-normal'
                    value={formData.address?.line2 || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.address?.line2}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
              {t('companyPage.zipcode')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="address.zipcode"
                    className='input-normal'
                    value={formData.address?.zipcode || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.address?.zipcode}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.city')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="address.city"
                    className='input-normal'
                    value={formData.address?.city || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.address?.city}
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.phoneNumber')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="phone_number"
                    className='input-normal'
                    value={formData.phone_number || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.phone_number}
              </div>
            </div>
            <div className="input-container-normal">
              <div className='input-header-normal'>
                {t('companyPage.website')} :
              </div>
              <div className='drawer-formfield-inactive'>
                {editingMode ? (
                  <Input
                    type="text"
                    name="web_site"
                    className='input-normal'
                    value={formData.web_site || ''}
                    onChange={handleInputChange}
                  />
                ) : formData.web_site}
              </div>
            </div>
          </div>
        </div>
      </div>
      
      <div className="card-component">
        <div className="card-header">
          <div>
            <Icon
              path={mdiTextBoxOutline}
              color="#CED4DD"
              size="20px" />
            <span className="card-title">{t('companyPage.generalInfo')}</span>
          </div>
          <div className="headr" onClick={toggleCollapsed}>
            <Button
              appearance="secondary"
              className="button-instance"
              hasAfterIcon={false}
              hasBeforeIcon={false}
              hasDropdownIndicator={false}
              intent="none"
              label="Editer"
              size="small"
              stateProp="normal"
              type="default"
              customBeforeIcon={mdiAccountOutline}
              customAfterIcon={mdiPlus}
            />
          </div>
        </div>
        <div className="card-content">
          <form onSubmit={handleSubmit}>
            <div className="inputs-row">
              <div className="input-container-normal">
                <div className='input-header-normal'>
                {t('companyPage.active')} :
                </div>
                <div className='drawer-formfield-inactive'>
                  {editingMode ? (
                    <Input
                      type="number"
                      name="active"
                      value={formData.active || ''}
                      onChange={handleInputChange}
                    />
                  ) : formData.active}
                </div>
              </div>
            </div>
            <div className="inputs-row">
              <div className="input-container-normal">
                <div className='input-header-normal'>
                  {t('companyPage.referencedSince')} :
                </div>
                <div className='drawer-formfield-inactive'>
                  {editingMode ? (
                    <Input
                      type="date"
                      name="date_referenced"
                      className='input-normal'
                      value={formData.date_referenced || ''}
                      onChange={handleInputChange}
                    />
                  ) : convertTimestampToDate(formData.date_referenced)}
                </div>
              </div>
              <div className="input-container-normal">
                <div className='input-header-normal'>
                  {t('companyPage.referent')} :
                </div>
                <div className='drawer-formfield-inactive'>
                  {editingMode ? (
                    <Input
                      type="text"
                      name="referent"
                      value={formData.referent || ''}
                      onChange={handleInputChange}
                    />
                  ) : formData.referent}
                </div>
              </div>
            </div>
            {editingMode && (
              <div>
                <button type="submit" disabled={loading}>Save Changes</button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default CompanyPage;
