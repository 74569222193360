import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';
import { mdiCalendar } from '@mdi/js';
import { Input } from "../../components/Input";
import { convertTimestampToDate, countWorkdays } from '../../utils/dateUtils';
import CardHeader from '../CardHeader';
import "./style.css";

export const CardRfpDates = ({
  client_id,
  last_date,
  start_date,
  end_date,
  opt_start_date,
  opt_end_date,
  closing,
  onDatesUpdated,
  handleSubmit,
  editButton,
  context = 'default'
}) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [editedDates, setEditedDates] = useState({
    last_date: last_date,
    start_date: start_date,
    end_date: end_date,
    opt_start_date: opt_start_date,
    opt_end_date: opt_end_date
  });

  const initialDatesRef = useRef({
    last_date: last_date,
    start_date: start_date,
    end_date: end_date,
    opt_start_date: opt_start_date,
    opt_end_date: opt_end_date
  });

  useEffect(() => {
    setEditedDates({
      last_date: last_date,
      start_date: start_date,
      end_date: end_date,
      opt_start_date: opt_start_date,
      opt_end_date: opt_end_date,
      closing: countWorkdays(Date.now(), last_date)
    });
  }, [last_date, start_date, end_date, opt_start_date, opt_end_date]);

  const toggleEdit = async () => {
    if (isEditing) {
      await handleSubmit();
    }
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditedDates((prevDates) => {
      const newDates = { ...prevDates, [name]: value };
      onDatesUpdated(newDates);
      return newDates;
    });
  };

  const handleDiscard = () => {
    setEditedDates(initialDatesRef.current);
    if (onDatesUpdated) {
      onDatesUpdated(initialDatesRef.current);
    }
    setIsEditing(!isEditing);
  };

  return (
    <div className="card-component">
      <CardHeader
        isEditing={!isEditing}
        context="editor"
        editButton={editButton}
        iconPath={mdiCalendar}
        title={t('cardRfpDates.title')}
        handleDiscard={handleDiscard}
        toggleEdit={toggleEdit}
      />
      <div className="card-content card-content-v-padding">
        {context !== 'mission' && (
          <div className="card-row-std">
            <div className="card-languages-left">
              <div className="card-label">
                {t('cardRfpDates.lastDate')}:
              </div>
              <div className="card-maincontent">
                {isEditing ? (
                  <Input
                    type="date"
                    name="last_date"
                    value={editedDates.last_date}
                    onChange={handleInputChange}
                    iconAfter={mdiCalendar}
                  />
                ) : (
                  convertTimestampToDate(editedDates.last_date)
                )}
                {!isEditing && (
                  <span className="card-warningtxt"> {t('cardRfpDates.closing')} J-{closing}</span>
                )}
              </div>
            </div>
          </div>
        )}

        <div className="card-row-std">
          <div className="card-languages-left">
            <div className="card-label">
              {t('cardRfpDates.startDate')}:
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input
                  type="date"
                  name="start_date"
                  value={editedDates.start_date}
                  onChange={handleInputChange}
                  iconAfter={mdiCalendar}
                />
              ) : (
                convertTimestampToDate(editedDates.start_date)
              )}
            </div>
          </div>
          <div>
            <div className="card-label">
              {t('cardRfpDates.endDate')}:
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input
                  type="date"
                  name="end_date"
                  value={editedDates.end_date}
                  onChange={handleInputChange}
                  iconAfter={mdiCalendar}
                />
              ) : (
                convertTimestampToDate(editedDates.end_date)
              )}
            </div>
          </div>
        </div>
        <div>
          <div className="card-label">
            {t('cardRfpDates.missionDuration')}:
          </div>
          <div className="card-maincontent">
            {countWorkdays(editedDates.start_date, editedDates.end_date)} {t('cardRfpDates.days')}
          </div>
        </div>
        <div className="card-row-std">
          <div className="card-languages-left">
            <div className="card-label">
              {t('cardRfpDates.optionalPeriodStart')}:
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input
                  type="date"
                  name="opt_start_date"
                  value={editedDates.opt_start_date}
                  onChange={handleInputChange}
                  iconAfter={mdiCalendar}
                />
              ) : (
                convertTimestampToDate(editedDates.opt_start_date)
              )}
            </div>
          </div>
          <div>
            <div className="card-label">
              {t('cardRfpDates.optionalPeriodEnd')}:
            </div>
            <div className="card-maincontent">
              {isEditing ? (
                <Input
                  type="date"
                  name="opt_end_date"
                  value={editedDates.opt_end_date}
                  onChange={handleInputChange}
                  iconAfter={mdiCalendar}
                />
              ) : (
                convertTimestampToDate(editedDates.opt_end_date)
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
