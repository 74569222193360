// RfpRequirements.js
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Status } from "../components/Status";
import { Select } from '../mycomponents/Select';
import { Input } from '../components/Input';
import { getSkillColor, getSkillLabel, applicantSkillLevelOptions } from '../utils/StatusSkills';

const RfpRequirements = ({ state, onStateChange, context='default', isAdmin }) => {
    const { t } = useTranslation();
    useEffect(() => {
        onStateChange(state);
    }, [state, onStateChange]);

    const handleSkillLevelChange = (type, index, newValue) => {
        const newState = { ...state };
        newState[type][index].candidateLevel = newValue;
        onStateChange(newState);
    };

    const handleInputChange = (type, index, event) => {
        const newState = { ...state };
        newState[type][index].comment = event.target.value;
        onStateChange(newState);
    };

    let classes = 'grid-container grid-container-right-panel';
    if (context==='default'){
        classes = 'grid-container';
    }
    if (!isAdmin){
        classes = 'grid-container-tc';
    }

    const renderList = (type) => (

        <ul>
            <div className={classes}>
                <div className='grid-header header-xsmall'>Compétences demandées</div>
                {isAdmin &&(
                    <div className='grid-header header-xsmall'>Niveau attendu</div>
                )}
                <div className='grid-header header-xsmall'>Niveau du candidat</div>
                <div className='grid-header header-xsmall'>Commentaire (facultatif)</div>
            </div>
            {state[type]?.length > 0 ? (

                state[type].map((item, index) => (
                    <li key={index} className={classes}>
                        <div className='grid-item'>{item.title || 'N/A'}</div>
                        {isAdmin && (
                            <div className='grid-item'>
                                <Status
                                    className="status-instance"
                                    color={getSkillColor(item.level)}
                                    emphasis="strong"
                                    label={getSkillLabel(item.level)}
                                    style={{ width: '100px' }}
                                />
                            </div>
                        )}

                        <div className='grid-item select-container'>
                            {context==='default' ?(
                                <Select
                                options={applicantSkillLevelOptions}
                                onChange={(newValue) => handleSkillLevelChange(type, index, newValue)}
                                value={item.candidateLevel}
                            />
                            ):(
                               <>
                               <Status
                                className="status-instance"
                                color={getSkillColor(item.candidateLevel)}
                                emphasis="strong"
                                label={getSkillLabel(item.candidateLevel)}
                                style={{ width: '100px' }}
                            />
                               </>
                            )}
                            

                        </div>
                        <div className='grid-item'>
                            <Input
                                type="text"
                                value={item.comment}
                                onChange={(event) => handleInputChange(type, index, event)}
                                disabled={false}
                            />
                        </div>
                    </li>
                ))
            ) : (
                <li className='grid-item'> {type === 'diplomas' ? t('rfpRequirements.noDiplomas"') : type === 'skills' ? t('rfpRequirements.noSkills"') : t('rfpRequirements.noLangs"')}.</li>
            )}
        </ul>
    );

    return (
        <div className='full-width card-component padding-fix'>
            <div className='header-xsmall v-padding-fix'>{t('editApplicant.diplomas')}</div>
            <section className='wizard-skillmatch-borders'>
                {renderList('diplomas')}
            </section>
            <div className='header-xsmall v-padding-fix'>{t('editApplicant.skills')}</div>
            <section className='wizard-skillmatch-borders'>
                {renderList('skills')}
            </section>
            <div className='header-xsmall v-padding-fix'>{t('editApplicant.languages')}</div>
            <section className='wizard-skillmatch-borders'>
                {renderList('languages')}
            </section>
        </div>
    );
};

export default RfpRequirements;
