// components/CreatePartnerPage.jsx

import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { Input } from '../components/Input';
import { Select } from '../mycomponents/Select';
import { Button } from "../components/Button";
import { mdiAccountOutline, mdiPlus } from '@mdi/js';
import { CategoryOptions, LegalStatusOptions } from '../utils/corpUtils';
import { CardPartnerBankInfo } from '../mycomponents/CardPartnerBankInfo';
import { CardPartnerAddress } from '../mycomponents/CardPartnerAddress';
import { Alert } from "../components/Alert";
import { fieldLabels, errorMessages } from '../utils/errorTranslations'; // Importez les traductions

const CreatePartnerPage = ({ onSuccess }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe();  // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);

  const [fieldErrors, setFieldErrors] = useState({});
  const [formData, setFormData] = useState({
    partnerData: {
      active: 0,
      name: '',
      vat_nbr: '',
      siren: '',
      rcs: '',
      legal_status: '',
      category: '',
      capital: 0,
      phone_number: '',
      web_site: '',
      address: '',
      billing_addr: '',
      bank_details: '',
      rib: {
        bankName: '',
        accountnbr: '',
        iban: '',
        bic: '',
        key: '',
        bankid: ''
      }
    },
    userData: {
      firstName: '',
      lastName: '',
      email: '',
      phone: ''
    }
  });

  const handleLegalStatusChange = (newValue) => {
    setFormData({ ...formData, partnerData: { ...formData.partnerData, legal_status: newValue } });
  };

  const handleCategoryChange = (newValue) => {
    setFormData({ ...formData, partnerData: { ...formData.partnerData, category: newValue } });
  };

  const [error, setError] = useState(null);

  const handleInputChange = (event, isUserData = false) => {
    const { name, value } = event.target;
    if (isUserData) {
      setFormData({ 
        ...formData, 
        userData: { ...formData.userData, [name]: value } 
      });
    } else {
      setFormData({ 
        ...formData, 
        partnerData: { ...formData.partnerData, [name]: value } 
      });
    }
  
    // Remove the error for the specific field
    setFieldErrors(prevErrors => {
      const { [name]: removed, ...rest } = prevErrors;
      return rest;
    });
  };
  
  const handleAddressChange = (updatedAddress) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      partnerData: {
        ...prevFormData.partnerData,
        address: updatedAddress
      }
    }));
  };
  
  const handleBillingAddressChange = (updatedAddress) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      partnerData: {
        ...prevFormData.partnerData,
        billing_addr: updatedAddress
      }
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(null);
    setFieldErrors({}); // Clear previous field errors
  
    try {
      // Consolidate firstName and lastName into name
      const consolidatedName = `${formData.userData.firstName} ${formData.userData.lastName}`.trim();
  
      // Create a merged payload that includes userData with the consolidated name
      const partnerPayload = {
        ...formData.partnerData,
        userData: {
          name: consolidatedName,
          email: formData.userData.email,
          phone: formData.userData.phone
        }
      };
  
      // Submit the merged partner data
      const partnerResponse = await api.post('/partner', partnerPayload);
  
      // Check if the partner was created successfully
      if (partnerResponse.data) {
        // Extract necessary data from partnerResponse
        const { id: partner_id, name: partner_name } = partnerResponse.data;
  
        // Prepare data for /admin/createUser endpoint
        const userPayload = {
          username: formData.userData.email, // Mapping email to username
          maincontact: true, // Assuming the created user is the main contact
          partner_id, // From partnerResponse
          firstName: formData.userData.firstName,
          lastName: formData.userData.lastName,
          partner_name, // From partnerResponse
          type: 'partner', // As per requirement
          phone: formData.userData.phone
        };
  
        // Make the API call to create the user
        const userResponse = await api.post('/admin/createUser', userPayload);
  
        // Optionally, you can check userResponse if needed
        if (userResponse.data) {
          console.log('Utilisateur créé avec succès:', userResponse.data);
        }
      }
  
      // Trigger the onSuccess callback if provided
      if (onSuccess) {
        onSuccess(); // Refresh the client list and close the drawer
      }
    } catch (error) {
      // Enhanced error handling
      const serverError = error.response?.data;
  
      if (serverError) {
        // Handle field-specific errors
        if (serverError.fields && Array.isArray(serverError.fields)) {
          const newFieldErrors = {};
          serverError.fields.forEach(({ field, message }) => {
            const frenchField = fieldLabels[field] || field;
            let frenchMessage = message;

            // Traduisez le message d'erreur en français en fonction du type d'erreur
            // Supposons que le message est du type "field must be unique."
            if (message.includes('must be unique') || message.includes('not_unique')) {
              frenchMessage = `${frenchField} ${errorMessages.unique}`;
            } else if (message.includes('cannot be null') || message.includes('notNull')) {
              frenchMessage = `${frenchField} ${errorMessages.notNull}`;
            }
            // Ajoutez d'autres traductions si nécessaire

            newFieldErrors[field] = frenchMessage;
          });
          setFieldErrors(newFieldErrors);
        }
  
        // Handle general errors
        if (serverError.error) {
          // Traduire le message d'erreur général si nécessaire
          setError(serverError.error); // Vous pouvez également traduire ceci
        } else if (serverError.message) {
          setError(serverError.message);
        } else {
          setError('Une erreur inattendue est survenue.');
        }
      } else {
        // Handle errors without a server response
        setError(error.message || 'Une erreur inattendue est survenue.');
      }
    }
  };

  return (
    <div className="drawer-container">
      <div className="header drawer-title">
        <div className="rfptitle">{t('newPartner.title')}</div>
        <div className="headr">
          <Button
            appearance="primary"
            className="button-instance"
            hasAfterIcon={false}
            hasBeforeIcon={false}
            hasDropdownIndicator={false}
            intent="positive"
            label={t('newPartner.save')}
            size="medium-default"
            stateProp="normal"
            type="default"
            customBeforeIcon={mdiAccountOutline}
            customAfterIcon={mdiPlus}
            onClick={handleSubmit}
          />
        </div>
      </div>

      {/* Afficher les erreurs générales et spécifiques dans l'Alert en français */}
      {(error || Object.keys(fieldErrors).length > 0) && (
        <div className='input-container-normal h-padding-l'>
          <Alert
            className="alert-instance"
            intent="negative"
            text={
              <>
                {error && <div>{error}</div>}
                {Object.keys(fieldErrors).length > 0 && (
                  <ul>
                    {Object.entries(fieldErrors).map(([field, message]) => (
                      <li key={field}>{message}</li>
                    ))}
                  </ul>
                )}
              </>
            }
            title=''
            showTitle={false}
          />
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <div className="drawer-rfp-form-bloc">
          <div className="header-xsmall">{t('newPartner.information')}</div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.name')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.partnerData.name}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.rcs')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="rcs"
                  name="rcs"
                  value={formData.partnerData.rcs}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.vatNumber')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="vat_nbr"
                  name="vat_nbr"
                  value={formData.partnerData.vat_nbr}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.siren')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="siren"
                  name="siren"
                  value={formData.partnerData.siren}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.legalStatus')}:</div>
              <div className="input-content-normal">
                <Select
                  name="legal_status"
                  value={formData.partnerData.legal_status}
                  options={LegalStatusOptions}
                  onChange={handleLegalStatusChange}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.companyCategory')}:</div>
              <div className="input-content-normal">
                <Select
                  name="category"
                  value={formData.partnerData.category}
                  options={CategoryOptions}
                  onChange={handleCategoryChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="drawer-rfp-form-bloc">
          <div className="header-xsmall">{t('newPartner.mainContact')}</div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.email')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="email"
                  name="email"
                  value={formData.userData.email}
                  onChange={(event) => handleInputChange(event, true)}
                />
              </div>
            </div>
          </div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.firstName')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.userData.firstName}
                  onChange={(event) => handleInputChange(event, true)}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.lastName')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.userData.lastName}
                  onChange={(event) => handleInputChange(event, true)}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.phone')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.userData.phone}
                  onChange={(event) => handleInputChange(event, true)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="drawer-rfp-form-bloc">
          <div className="header-xsmall">{t('newPartner.coordinates')}</div>
          <div className="inputs-row">
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.phoneNumber')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="phone"
                  name="phone"
                  value={formData.partnerData.phone_number}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.website')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="web_site"
                  name="web_site"
                  value={formData.partnerData.web_site}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="input-container-normal">
              <div className="input-header-normal">{t('newPartner.capital')}:</div>
              <div className="input-content-normal">
                <Input
                  type="text"
                  id="capital"
                  name="capital"
                  value={formData.partnerData.capital}
                  onChange={handleInputChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="full-width">
          <CardPartnerAddress
            context="create"
            edit={true}
            title={t('newPartner.address')}
            address={formData.partnerData.address}
            handleSubmit={handleSubmit}
            onAddressChange={handleAddressChange}
            editButton={true}
          />
        </div>
        <div className="full-width">
          <CardPartnerAddress
            context="create"
            edit={true}
            title={t('newPartner.billingAddress')}
            address={formData.partnerData.billing_addr}
            handleSubmit={handleSubmit}
            onAddressChange={handleBillingAddressChange}
            editButton={true}
          />
        </div>
        <div className="full-width">
          <CardPartnerBankInfo
            context="create"
            edit={true}
            bankInfo={formData.partnerData.rib}
            handleSubmit={handleSubmit}
            onBankInfoChange={(updatedBankInfo) =>
              setFormData((prevFormData) => ({
                ...prevFormData,
                partnerData: {
                  ...prevFormData.partnerData,
                  rib: updatedBankInfo,
                },
              }))
            }
            editButton={true}
          />
        </div>
      </form>
      {error && <div>{t('newPartner.error')}: {error}</div>}
    </div>
  );
  
};

export default CreatePartnerPage;
