import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useState, useEffect } from 'react';
import api, { setupInterceptors } from '../middlewares/api';
import { useAuth } from '../middlewares/useAuth';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { convertTimestampToDate } from '../utils/dateUtils';

export default function BasicTable() {
    const { t } = useTranslation(); 
    const auth = useAuth();
    const navigate = useNavigate();
    const [rfpStatusCounts, setRfpStatusCounts] = useState([]);
    const [clients, setClients] = useState([]);

    useEffect(() => {
        if (auth.initialized) {
            const unsubscribe = setupInterceptors(auth.updateToken);
            return () => unsubscribe();
        }
    }, [auth.initialized, auth.updateToken]);

    useEffect(() => {
        const fetchClients = async () => {
            try {
                const response = await api.get(`/client`);
                if (response.status === 200 && response.data.items) {
                    setClients(response.data.items);
                } else {
                    console.error(t('clientList.unexpectedResponseStructure'), response);
                }

                const rfpStatusResponse = await api.get(`/rfp/statusCounts`);
                if (Array.isArray(rfpStatusResponse.data)) {
                    setRfpStatusCounts(rfpStatusResponse.data);
                } else {
                    console.error(t('clientList.invalidData'), rfpStatusResponse.data);
                    setRfpStatusCounts([]);
                }
            } catch (error) {
                console.error(t('clientList.fetchError'), error);
            }
        };
        fetchClients();
    }, [t]);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label={t('clientList.simpleTable')}>
                <TableHead>
                    <TableRow>
                        <TableCell align="left">
                            <span className='list-table-header'>{t('clientList.clientName')}</span>
                        </TableCell>
                        <TableCell align="center">
                            <span className='list-table-header'>{t('clientList.drafts')}</span>
                        </TableCell>
                        <TableCell align="center">
                            <span className='list-table-header'>{t('clientList.rfpsToValidate')}</span>
                        </TableCell>
                        <TableCell align="center">
                            <span className='list-table-header'>{t('clientList.activeRfps')}</span>
                        </TableCell>
                        <TableCell align="center">
                            <span className='list-table-header'>{t('clientList.completedRfps')}</span>
                        </TableCell>
                        <TableCell align="center">
                            <span className='list-table-header'>{t('clientList.applicationsToReview')}</span>
                        </TableCell>
                        <TableCell align="left">
                            <span className='list-table-header'>{t('clientList.mainContact')}</span>
                        </TableCell>
                        <TableCell align="right">
                            <span className='list-table-header'>{t('clientList.creationDate')}</span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {clients.map((client) => {
                        const clientStatusCounts = rfpStatusCounts.filter(rfp => rfp.id_client === client.id) || [];
                        const draftCount = clientStatusCounts.find(rfp => rfp.status === 0)?.statusCount || 0;
                        const reviewCount = clientStatusCounts.find(rfp => rfp.status === 1)?.statusCount || 0;
                        const activeCount = clientStatusCounts.find(rfp => rfp.status === 2)?.statusCount || 0;
                        const closedCount = clientStatusCounts.find(rfp => rfp.status === 3)?.statusCount || 0;
                        const totalApplications = clientStatusCounts.reduce((acc, curr) => acc + parseInt(curr.applicationCount || 0), 0);

                        return (
                            <TableRow
                                key={client.id}
                                hover={true}
                                onClick={() => navigate(`/clients/${client.id}`)}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell align="left">
                                    <span className='list-rfp-title'>{client.name}</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span className={`list-rfp-content ${draftCount > 0 ? 'counter number' : ''}`}>{draftCount}</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span className={`list-rfp-content ${reviewCount > 0 ? 'counter number' : ''}`}>{reviewCount}</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span className={`list-rfp-content ${activeCount > 0 ? 'counter number' : ''}`}>{activeCount}</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span className={`list-rfp-content ${closedCount > 0 ? 'counter number' : ''}`}>{closedCount}</span>
                                </TableCell>
                                <TableCell align="center">
                                    <span className={`list-rfp-content ${totalApplications > 0 ? 'counter number' : ''}`}>{totalApplications}</span>
                                </TableCell>
                                <TableCell align="left">
                                    <span className='list-rfp-content'>{client?.contact?.name}</span>
                                </TableCell>
                                <TableCell align="right">
                                    <span className='list-rfp-content'>{convertTimestampToDate(client.createdAt)}</span>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
