import "./style.css";
import React from "react";
import { useTranslation } from 'react-i18next';
import { mdiAccountOutline, mdiPlus, mdiBadgeAccountHorizontalOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Button } from '../../components/Button';
import api, { setupInterceptors } from '../../middlewares/api';
import { useAuth } from '../../middlewares/useAuth';
import { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Drawer from '@mui/material/Drawer';
import ApplicationDetails from '../ApplicationDetails';
import { Status } from "../../components/Status";
import { convertTimestampToDate } from '../../utils/dateUtils';
import PartnerNameById from "../PartnerNameById";
import { statusOptions } from '../../utils/rfpStatusOptions';

export const CardMyApplications = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  useEffect(() => {
    if (auth.initialized) {
      const unsubscribe = setupInterceptors(auth.updateToken);
      return () => unsubscribe(); // Ensure cleanup is called on component unmount
    }
  }, [auth.initialized, auth.updateToken]);  

  const fetchApplicationsRef = useRef(null);
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const fetchApplications = async () => {
      try {
        const response = await api.get(`/rfp/myapplications/list`);
        setApplications(response.data);
      } catch (error) {
        console.error(t('cardMyApplications.errorFetching'), error);
      }
    };

    fetchApplicationsRef.current = fetchApplications;
    fetchApplicationsRef.current();
  }, [auth.partner_id]);

  const openDrawer = (applicationId) => {
    setSelectedApplication(applicationId);
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setSelectedApplication(null);
    setDrawerOpen(false);
  };

  const handleSave = async () => {
    closeDrawer();
    if (fetchApplicationsRef.current) {
      await fetchApplicationsRef.current();
    }
    setRefresh(refresh + 1);
  };

  const totalRate = (rate, partner_margin_rate, client_margin_rate) => {
    const numericRate = parseFloat(rate) || 0;
    const calculateCommission = (marginRate) => {
      return ((marginRate / 100) * numericRate).toFixed(2);
    };

    const partnerCommission = parseFloat(calculateCommission(parseFloat(partner_margin_rate)));
    const clientCommission = parseFloat(calculateCommission(parseFloat(client_margin_rate)));
    const totalCommission = partnerCommission + clientCommission;

    return (numericRate + totalCommission).toFixed(2);
  };

  const getStatusColor = (index) => {
    try {
      return statusOptions[index].color;
    } catch (e) {
      return 'neutral';
    }
  };

  const getStatusLabel = (index) => {
    try {
      return statusOptions[index].label;
    } catch (e) {
      return t('cardMyApplications.statusNotAvailable');
    }
  };

  return (
    <div className="card-component">
      <div className="card-header">
        <div>
          <Icon
            path={mdiBadgeAccountHorizontalOutline}
            color="#CED4DD"
            size="20px" />
          <h3 className="card-title">{t('cardMyApplications.latestApplications')}</h3>
        </div>
        <div className="headr">
          <Button
            appearance="secondary"
            className="button-instance"
            hasAfterIcon={false}
            hasBeforeIcon={false}
            hasDropdownIndicator={false}
            intent="none"
            label={t('cardMyApplications.viewAll')}
            size="small"
            stateProp="normal"
            type="default"
            customBeforeIcon={mdiAccountOutline}
            customAfterIcon={mdiPlus}
          />
        </div>
      </div>
      <div className="card-content">
        <div className="card-maincontent">
          <TableContainer component={Paper} sx={{ boxShadow: 'none', border: 'none' }}>
            {applications.length === 0 ? (
              <p className="h-padding-s">{t('cardMyApplications.noApplications')}</p>
            ) : (
              <Table sx={{ minWidth: 670, border: 'none' }} aria-label="simple table">
                <TableHead>
                  <TableRow className="tabheader">
                    <TableCell align="left"><span className='list-table-header'>{t('cardMyApplications.columnTitle')}</span></TableCell>
                    <TableCell align="left"><span className='list-table-header'>{t('cardMyApplications.columnClient')}</span></TableCell>
                    <TableCell align="left"><span className='list-table-header'>{t('cardMyApplications.columnReference')}</span></TableCell>
                    <TableCell align="left"><span className="list-table-header">{t('cardMyApplications.columnCandidate')}</span></TableCell>
                    <TableCell align="left"><span className='list-table-header'>{t('cardMyApplications.columnRate')}</span></TableCell>
                    <TableCell align="left"><span className='list-table-header'>{t('cardMyApplications.columnStatus')}</span></TableCell>
                    <TableCell align="left"><span className='list-table-header'>{t('cardMyApplications.columnSentDate')}</span></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {applications.map((row) => (
                    <TableRow
                      key={row.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      onClick={() => openDrawer(row.id)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell align="left"><span className='list-rfp-content'>{row.rfp.rfp_title}</span></TableCell>
                      <TableCell align="left"><span className='list-rfp-content'><PartnerNameById id={row.rfp.id_client} type="client" /></span></TableCell>
                      <TableCell align="left"><span className='list-rfp-content'>{row.rfp.client_ref}</span></TableCell>
                      <TableCell align="left"><span className='list-rfp-content'>{row.first_name} {row.last_name} ({row.trigraph})</span></TableCell>
                      <TableCell align="left">
                        <span className='list-rfp-content'>{
                          totalRate(
                            row.rate || '0',
                            row.offer_details.partner_margin_rate || '0',
                            row.offer_details.client_margin_rate || '0'
                          )} €
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <Status
                          className="status-instance"
                          color={getStatusColor(row.status)}
                          emphasis="strong"
                          label={getStatusLabel(row.status)}
                          style={{ width: '100px' }}
                        />
                      </TableCell>
                      <TableCell align="left"><span className='list-rfp-content'>{convertTimestampToDate(row.createdAt)}</span></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </TableContainer>
        </div>
      </div>
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={closeDrawer}
      >
        {selectedApplication && (
          <div>
            <ApplicationDetails applicationId={selectedApplication} onSave={handleSave} />
          </div>
        )}
      </Drawer>
    </div>
  );
};
