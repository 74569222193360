// ListApplicant.js
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Drawer from '@mui/material/Drawer';
import ApplicantListAndApply from '../mycomponents/ApplicantListAndApply';
import { useNavigate } from 'react-router-dom';

const ApplicantListPage = () => {
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const navigate = useNavigate();
 
  const closeDrawer = () => {
    setDrawerOpen(false);
  };
  const handleApplicantSelect = (applicantId) => {
    navigate(`/applicants/${applicantId}`);
};
  return (
    <div className='list-rfp-container'>
      <div className='list-rfp-header'>
        <div className='list-rfp-header-content-l'>
          {t('listApplicants.header')}
        </div>
        <div className='list-rfp-header-content-r'>

        </div>
      </div>
      <div style={{ display: 'flex', height: '100%', minHeight: '400px' }}>
        <ApplicantListAndApply onSelectApplicant={handleApplicantSelect} />
      </div>
      <div>
        <Drawer
          anchor="right"
          open={drawerOpen}
          onClose={closeDrawer}
        >
          <div>PLACEHOLDER NEW APPLICANT FORM</div>
        </Drawer>
      </div>
    </div>
  );
}

export default ApplicantListPage;
